<div class="step-content">
  <section class="section-step1">
    <h6>{{ 'step4.section-step1.title' | translate }}</h6>
    <div>
      <p class="info-text">
        <span class="title">{{ 'step4.section-step1.region-choice' | translate }} :</span>
        {{ 'section-situation.region.' + ($selectedRegion | async)?.id | translate }}
      </p>
      <p class="info-text">
        <span class="title">{{ 'step4.section-step1.description' | translate }} :</span>
        {{ 'estateTpes.' + ($estateType | async) | translate }}
      </p>
      <article>
        <p class="info-text">
          <span class="title">{{ 'step4.section-step1.your-services' | translate }} :</span>
        </p>
        <ul>
          <li *ngFor="let product of $products | async">
            {{ 'section-money.reductions.' + product.id | translate }}
          </li>
        </ul>
      </article>
    </div>
  </section>
  <section class="section-step2">
    <h6>{{ 'section-user.disco.your-info' | translate }}</h6>
    <div *ngIf="$visitAddress | async as visitAddress">
      <p class="info-text">
        <span class="title"
          >{{
            isHainautOrNamurorNVN
              ? ('MDN-hainaut-namur-nvn.section-propriete.title' | translate)
              : ('section-propriete.title' | translate)
          }}
          :</span
        >
        {{ visitAddress.street }} {{ visitAddress.number }} {{ visitAddress.boxNumber }}
        {{ visitAddress.apartmentNumber }}, {{ visitAddress.zip }} {{ visitAddress.city }}
      </p>
      <p class="info-text" *ngIf="$goGetKeys | async as goGetKey">
        <span class="title">{{ 'section-accueil.title' | translate }} :</span>
        <span *ngIf="$user | async as user">
          <span *ngIf="goGetKey && ($userType | async) === userTypes.Notaire">
            {{ 'section-accueil.key' | translate }} {{ 'section-accueil.at-notary' | translate }} -
            {{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
            {{ user.address.apartmentNumber }}, {{ user.address.zip }} {{ user.address.city }}
          </span>
          <span *ngIf="goGetKey && ($userType | async) === userTypes.Agence">
            {{ 'section-accueil.key' | translate }} {{ 'section-accueil.at-agency' | translate }} -
            {{ user.address.street }} {{ user.address.number }} {{ user.address.boxNumber }}
            {{ user.address.apartmentNumber }}, {{ user.address.zip }} {{ user.address.city }}
          </span>
        </span>
        <span *ngIf="!goGetKey && ($contact | async) as contact">
          <span> {{ contact.firstname }} {{ contact.lastname }} - Tel: {{ contact.phone }}</span>
        </span>
      </p>
      <p *ngIf="$dateButoir | async as dateButoir" class="info-text">
        <span class="title">{{ 'section-accueil.date-butoir.title' | translate }} :</span>
        {{ dateButoir | date: 'd/M/yy' }}
      </p>
    </div>
  </section>
  <section class="section-facture">
    <h6>{{ 'step4.section-facture.title' | translate }}</h6>
    <div>
      <div class="checked-info">
        <p>
          {{ 'step4.section-facture.checked-info' | translate }}
          <a href="" target="_blank">{{ 'step4.section-facture.espace-client' | translate }}</a>
        </p>
      </div>
      <div class="checked-info">
        <p>
          {{ 'step4.section-facture.send-by-email' | translate }} :
          <em *ngIf="($userType | async) === userTypes.Particulier; else notPart">{{ ($user | async)?.email }}</em>
          <ng-template #notPart>
            <em>{{ ($user | async)?.email }} {{ ($owner | async)?.email ? '| ' + ($owner | async)?.email : null }}</em>
          </ng-template>
        </p>
      </div>
      <mat-checkbox *ngIf="step4ShowByMail" (change)="toggleSendByMail()" [checked]="$sendByMail | async">
        {{ 'step4.section-facture.send-by-mail' | translate }} :
        <em>
          {{ ($document | async)?.firstName }} {{ ($document | async)?.lastName }} -
          {{ ($document | async)?.address?.street }} {{ ($document | async)?.address?.number }}
          {{ ($document | async)?.address?.boxNumber }} {{ ($document | async)?.address?.apartmentNumber }},
          {{ ($document | async)?.address?.zip }} {{ ($document | async)?.address?.city }}
          {{
            ($document | async)?.address?.country !== undefined ? '- ' + ($document | async)?.address?.country : null
          }}
        </em>
      </mat-checkbox>
      <!-- <div [ngClass]="{ sendByMailDiv: true, unchecked: !($sendByMail | async) }">
        <p><span>Nom complet :</span> Nabil Ben</p>
        <p><span>Adresse :</span> Rue Bolsa 48, 000 Liège, Belgique</p>
      </div> -->
    </div>
  </section>
  <ng-container *ngIf="showFinalOptions && !($isInstallateur | async)">
    <section *ngIf="$userType | async as userType" class="section-paiement">
      <h6>{{ 'step4.section-paiement.title' | translate }}</h6>
      <mat-radio-group [value]="$paymentType | async">
        <div *ngIf="!($isUrgent | async)" class="classic">
          <mat-radio-button value="classic" (change)="selectPayment('classic')">
            {{ 'step4.section-paiement.classic' | translate }}
          </mat-radio-button>
          <div *ngIf="($paymentType | async) === 'classic'" class="info-box info-classic">
            <fa-icon icon="info-circle"></fa-icon>
            <div>
              <p>{{ 'step4.section-paiement.virement' | translate }}.</p>
              <p>{{ 'step4.section-paiement.info' | translate }}.</p>
            </div>
          </div>
        </div>
        <div class="online">
          <mat-radio-button value="online" (change)="selectPayment('online')">
            {{ 'step4.section-paiement.online' | translate }} {{ !($isUrgent | async) ? ': - 10 €' : '' }}
          </mat-radio-button>
          <div *ngIf="($paymentType | async) === 'online' && !($isUrgent | async)" class="info-box info-online">
            <fa-icon icon="info-circle"></fa-icon>
            <div>
              <p>{{ 'step4.section-paiement.online-info' | translate }}.</p>
            </div>
          </div>
        </div>
        <!-- <div class="acte" *ngIf="userType !== userTypes.Particulier">
          <mat-radio-button value="acte" (change)="selectPayment('acte')">
            {{ 'step4.section-paiement.alacte' | translate }}
          </mat-radio-button>
          <div *ngIf="($paymentType | async) === 'acte'" class="info-box info-acte">
            <fa-icon icon="info-circle"></fa-icon>
            <div>
              <p>{{ 'step4.section-paiement.info-acte' | translate }}.</p>
              <div *ngIf="userType === userTypes.Agence">
                {{ 'step4.section-paiement.info-acte-a' | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="userType === userTypes.Notaire && ($paymentType | async) === 'acte'" class="options-acte">
            <mat-radio-group [value]="$acteType | async">
              <div [ngClass]="{ unchecked: ($acteType | async) != 'ILLPAY' }">
                <mat-radio-button value="ILLPAY" (change)="selectActe('ILLPAY')">
                  {{ 'step4.section-paiement.illpay' | translate }}
                </mat-radio-button>
              </div>
              <div [ngClass]="{ unchecked: ($acteType | async) != 'BDC' }">
                <mat-radio-button value="BDC" (change)="selectActe('BDC')">
                  {{ 'step4.section-paiement.bdc' | translate }}
                </mat-radio-button>
              </div>
              <div [ngClass]="{ registre: true, unchecked: ($acteType | async) != 'NN' }">
                <mat-radio-button value="NN" (change)="selectActe('NN')">
                  {{ 'step4.section-paiement.nn' | translate }} *
                </mat-radio-button>
                <mat-form-field *ngIf="($acteType | async) === 'NN'" appearance="outline">
                  <mat-label>{{ 'step4.section-paiement.nn-label' | translate }}</mat-label>
                  <input matInput #nn placeholder="84.07.13-678.90" (change)="setNN(nn.value)" [value]="$nn | async" />
                </mat-form-field>
                <div class="asterisk">* {{ 'step4.section-paiement.nn-label' | translate }}.</div>
              </div>
            </mat-radio-group>
            <div class="confirm">{{ 'step4.section-paiement.asterisk' | translate }}.​</div>
            <div class="confirm">
              {{ 'step4.section-paiement.asterisk-a' | translate }}
            </div>
          </div>
        </div> -->
      </mat-radio-group>
    </section>
  </ng-container>
  <section *ngIf="displayAskForExpertise && isEligibleUserForExpertise" class="extra-offer">
    <h6>{{ 'step4.section-extra-title' | translate }}</h6>
    <mat-checkbox (change)="toggleAskForExpertise($event.checked)" [checked]="$askForExpertise | async">
      {{ 'step4.section-extra-offer' | translate }}
    </mat-checkbox>
  </section>
  <section class="section-comment">
    <h6>{{ 'step4.section-comment.title' | translate }}</h6>
    <mat-form-field appearance="outline">
      <textarea
        [(ngModel)]="remarks"
        (change)="SetRemarks(remarks)"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        placeholder="{{ 'step4.section-comment.placeholder' | translate }}"
      ></textarea>
    </mat-form-field>
  </section>
  <section *ngIf="$language | async as language" class="section-conditions">
    <mat-checkbox (change)="toggleIAgree()" [checked]="$IAgree | async">
      {{ 'section-conditions.iagree-1' | translate }}
      <a
        [href]="
          language === 'nl'
            ? 'https://www.certinergie.be/nl/onze-algemene-voorwaarden/'
            : 'https://www.certinergie.be/fr/nos-conditions-generales/'
        "
        rel="noopener"
        target="_blank"
        >{{ 'section-conditions.conditions' | translate }}</a
      >
      {{ 'section-conditions.iagree-2' | translate }}
      <a
        [href]="
          language === 'nl'
            ? 'https://www.certinergie.be/nl/privacybeleid/'
            : 'https://www.certinergie.be/fr/declaration-de-confidentialite/'
        "
        rel="noopener"
        target="_blank"
      >
        {{ 'section-conditions.conf' | translate }}</a
      >.
      <small> ({{ 'section-conditions.required' | translate }})</small>
    </mat-checkbox>
    <p *ngIf="submitNotValid" class="caption error">{{ 'error-messages.conditions-agreement' | translate }}</p>
  </section>
</div>
<app-step-money-result></app-step-money-result>
<div class="step-actions">
  <button class="btn btn-previous" matStepperPrevious>{{ 'navigation.previous' | translate }}</button>
  <button class="btn-accent btn-next" [disabled]="!(isValid$ | async)" (click)="finalizeOrder()" matStepperNext>
    {{ 'navigation.validate' | translate }}
  </button>
</div>
