import { SetParutionComment } from './../../core/store/order/order.actions';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Print } from 'src/app/core/models/print';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { SetParutions, SetParutionFileLinks } from 'src/app/core/store/order';
import { getParutions, getUserID } from 'src/app/core/store/order/order.selectors';
import * as PRODUCTS from '../../core/data/products';
import { DataService } from 'src/app/core/services/data.service';
import { projectID } from 'src/app/core/data/theme-config';
import { ProductDB } from 'src/app/core/models/productDB';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { VENTES } from 'src/app/core/enums/vente.enum';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

// const URL = '/api/';
const URL = 'https://certiservices.azurewebsites.net/api/Order/UploadPhotoNotary';
// const URL = 'http://localhost:52646/api/Order/UploadPhotoNotary';

@Component({
  selector: 'app-print-request-dialog',
  templateUrl: './print-request-dialog.component.html',
  styleUrls: ['./print-request-dialog.component.scss']
})
export class PrintRequestDialogComponent implements OnInit {
  tomorrow = new Date();
  infoFormGroup: FormGroup;

  selectedChoice = 0;
  repeatNumber = 1;

  currentParu: Print = {
    id: -1,
    name: '',
    needPhoto: true,
    date: null,
    day: 0,
    price: 0,
    format: ''
  };

  public uploader: FileUploader = new FileUploader({
    url: URL,
    removeAfterUpload: false,
    autoUpload: true
  });
  fileUrl = 'https://certinergie.blob.core.windows.net/certinergieclientfiles/';

  showAddParu = false;
  editing = false;

  options = [];

  optionsNamur = [];

  uploadLinks = [];

  paruList: Print[] = [];

  defaultParu: Print;

  venteType = -1;

  userID: string;

  projectID = projectID;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; reason: number; type: string },
    private store: Store<AppState>,
    private _formBuilder: FormBuilder,
    private dataService: DataService
  ) {
    this.options =
      this.data.type === 'lavenir'
        ? [
            {
              id: PRODUCTS.ProximagNamurBW,
              name: 'L’Avenir Namur + lavenir.net',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.ProximagNamurBW)
            },
            {
              id: PRODUCTS.ProximagLuxembourg,
              name: 'L’Avenir Luxembourg + lavenir.net',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.ProximagLuxembourg)
            },
            {
              id: PRODUCTS.ProximagLiege,
              name: 'L’Avenir Liege + lavenir.net',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.ProximagLiege)
            },
            {
              id: PRODUCTS.ProximagHainautOcc,
              name: 'L’Avenir Hainaut Occidental + lavenir.net',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.ProximagHainautOcc)
            },
            {
              id: PRODUCTS.ProximagHainautOutOfOcc,
              name: 'L’Avenir Hainaut hors Occidental + lavenir.net',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.ProximagHainautOutOfOcc)
            }
          ]
        : projectID === 'namur' || projectID === 'liege'
        ? [
            {
              id: PRODUCTS.PublicationAvenir,
              name: 'L’Avenir',
              format: '',
              day: 6,
              ...this.getPubPrice(PRODUCTS.PublicationAvenir)
            },
            {
              id: PRODUCTS.PublicationProximagAndenne,
              name: 'Proximag Andenne',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagAndenne)
            },
            {
              id: PRODUCTS.PublicationProximagCiney_Condroz,
              name: 'Proximag Ciney/Condroz',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagCiney_Condroz)
            },
            {
              id: PRODUCTS.PublicationProximagDinant_Bauraing,
              name: 'Proximag Dinant/Beauraing',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagDinant_Bauraing)
            },
            {
              id: PRODUCTS.PublicationProximagGembloux,
              name: 'Proximag Gembloux',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagGembloux)
            },
            {
              id: PRODUCTS.PublicationProximagNamur,
              name: 'Proximag Namur',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagNamur)
            },
            {
              id: PRODUCTS.PublicationProximagPhilippeville,
              name: 'Proximag Philippeville',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagPhilippeville)
            },
            {
              id: PRODUCTS.PublicationProximagCouvinChimay,
              name: 'Proximag Couvin/Chimay',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagCouvinChimay)
            },
            {
              id: PRODUCTS.PublicationProximagCiney_Dinant_Beauraing,
              name: 'Proximag Ciney/Dinant/Beauraing',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagCiney_Dinant_Beauraing)
            },
            {
              id: PRODUCTS.PublicationProximagCharleroi,
              name: 'Proximag Charleroi',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagCharleroi)
            },
            {
              id: PRODUCTS.PublicationProximagBrabantWallonEst,
              name: 'Proximag Brabant Wallon Est',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagBrabantWallonEst)
            },
            {
              id: PRODUCTS.PublicationProximagHuy,
              name: 'Proximag Huy',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagHuy)
            },
            {
              id: PRODUCTS.PublicationProximagHesbaye,
              name: 'Proximag Hesbaye',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagHesbaye)
            },
            {
              id: PRODUCTS.PublicationProximagFamenne,
              name: 'Proximag Famenne',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagFamenne)
            },
            {
              id: PRODUCTS.PublicationProximagArdennes,
              name: 'Proximag Ardennes',
              format: '',
              day: 3,
              ...this.getPubPrice(PRODUCTS.PublicationProximagArdennes)
            }
          ]
        : [
            {
              id: PRODUCTS.PublicationVlan,
              name: 'Vlan (lundi)',
              format: '60 à 80 mm',
              day: 1,
              ...this.getPubPrice(PRODUCTS.PublicationVlan)
            },
            {
              id: PRODUCTS.VlanEditionLocal,
              name: 'Vlan Edition Locale (lundi)',
              format: '60 à 80 mm',
              day: 3,
              ...this.getPubPrice(PRODUCTS.VlanEditionLocal)
            },
            {
              id: PRODUCTS.PublicationLeSoir,
              name: 'Le Soir (jeudi)',
              format: '81 à 100 mm',
              day: 4,
              ...this.getPubPrice(PRODUCTS.PublicationLeSoir)
            },
            {
              id: PRODUCTS.PublicationNiewsblad,
              name: 'Niewsblad/standaard (samedi)',
              format: '130 mm',
              day: 6,
              ...this.getPubPrice(PRODUCTS.PublicationNiewsblad)
            },
            {
              id: PRODUCTS.PublicationHetLaatsteNieuws,
              name: 'Het Laatste Nieuws (samedi)',
              format: '85 à 120 mm',
              day: 6,
              ...this.getPubPrice(PRODUCTS.PublicationHetLaatsteNieuws)
            },
            {
              id: PRODUCTS.publicationLaLibre,
              name: 'La Libre Immo + La dernière Heure (jeudi)',
              format: '100 mm 2 colonnes',
              day: 4,
              ...this.getPubPrice(PRODUCTS.publicationLaLibre)
            },
            {
              id: PRODUCTS.PublicationBruzz,
              name: 'Bruzz (jeudi)',
              format: '',
              day: 4,
              ...this.getPubPrice(PRODUCTS.PublicationBruzz)
            },
            {
              id: PRODUCTS.PublicationDeZondag,
              name: 'De Zondag (dimanche)',
              format: '',
              day: 0,
              ...this.getPubPrice(PRODUCTS.PublicationDeZondag)
            }
          ];

    this.defaultParu = {
      id: this.options[0].id,
      name: this.options[0].name,
      needPhoto: true,
      date: this.nextDay(this.options[0].day),
      day: this.options[0].day,
      price: this.options[0].price,
      format: this.options[0].format
    };
    this.currentParu = { ...this.defaultParu };
  }

  ngOnInit() {
    this.store.select(getParutions).subscribe(p => {
      this.paruList = p;
    });

    this.store.select(getUserID).subscribe(us => (this.userID = us));

    this.infoFormGroup = this._formBuilder.group({
      instructions: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ]
    });

    this.uploader.onBuildItemForm = (item, form) => {
      item.withCredentials = false;
      form.append('userID', this.userID);
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.uploadLinks = [...this.uploadLinks, JSON.parse(response)];
      this.store.dispatch(new SetParutionFileLinks([...this.uploadLinks]));
    };
  }

  setSupportType(index) {
    this.currentParu.name = this.options[index].name;
    this.currentParu.day = this.options[index].day;
    this.currentParu.price = this.options[index].price;
    this.currentParu.format = this.options[index].format;
    this.currentParu.id = this.options[index].id;
    this.currentParu.commission = this.options[index].commission;
    this.currentParu.date = this.nextDay(this.options[index].day);
  }

  filterWeekend(d: Date) {
    const day = d.getDay();
    return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
  }

  filterOneDay(d: Date) {
    const day = d.getDay();
    return this.currentParu.day !== -1 ? day === this.currentParu.day : true;
  }

  addSupport() {
    if (this.currentParu.date) {
      let parutions = [];
      for (let index = 0; index < this.repeatNumber; index++) {
        const paruTmp = { ...this.currentParu };
        paruTmp.date = this.addDays(this.currentParu.date, 7 * index);
        parutions = [...parutions, paruTmp];
      }
      this.paruList = [...this.paruList, ...parutions];
      this.showAddParu = false;
    }
  }

  displayAddParu() {
    this.showAddParu = true;
    this.currentParu = { ...this.defaultParu };
  }

  deleteParu(index) {
    this.paruList = [...this.paruList];
    this.paruList.splice(index, 1);
  }

  editParu(index) {
    this.editing = true;
    this.showAddParu = true;
    this.currentParu = this.paruList[index];
    this.selectedChoice = this.options.indexOf(this.options.find(p => p.name === this.currentParu.name));
  }

  saveEditSupport() {
    this.editing = false;
    this.showAddParu = false;
  }

  close() {
    this.data.dialog.closeAll();
  }

  cancel() {
    const list = [...this.paruList].filter(pr => pr.id === PRODUCTS.FullPress);
    this.store.dispatch(new SetParutions(list));
    this.data.dialog.closeAll();
  }

  saveParu() {
    this.store.dispatch(new SetParutionComment(this.infoFormGroup.value.instructions));
    this.store.dispatch(new SetParutions(this.paruList));
    this.close();
  }

  backToList() {
    this.showAddParu = false;
  }

  getPubDB(id: number): ProductDB {
    if (projectID === 'default') {
      return this.dataService.products.find(pr => pr.ProductType === id);
    }
    this.venteType = this.data.reason === VENTES.VENTE_PUB ? 3 : this.data.reason;
    const product =
      this.dataService.products.find(
        pr => pr.ProductType === id && pr.SaleType === this.venteType && pr.WithPhoto === this.currentParu.needPhoto
      ) ||
      this.dataService.products.find(pr => pr.ProductType === id && pr.SaleType === this.venteType) ||
      this.dataService.products.find(pr => pr.ProductType === id);
    return product;
  }

  getPubPrice(id: number) {
    return { price: this.getPubDB(id).Price, commission: this.getPubDB(id).Commission };
  }

  get showPhotoSelect() {
    return this.data.reason === 1 || projectID === 'nvn' ? true : false;
  }

  onPhotoValueChanges() {
    setTimeout(() => {
      this.currentParu = { ...this.currentParu, ...this.getPubPrice(this.currentParu.id) };
    }, 100);
  }

  fileIndex(i: number) {
    return i + 1;
  }

  deletePhotoLink(index: number) {
    this.uploadLinks = [...this.uploadLinks];
    this.uploadLinks.splice(index, 1);
  }

  nextDay(x: number) {
    x = x === 0 ? 7 : x;
    const now = new Date();
    now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7) + 14);
    return now;
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  get finalPrice() {
    return this.repeatNumber !== 0 ? this.currentParu.price * this.repeatNumber : this.currentParu.price;
  }
}
