<mat-form-field appearance="outline" *ngIf="isMobile" class="big-select">
  <mat-select placeholder="placeholder" [value]="selected">
    <mat-select-trigger>
      <div [class]="'option ' + elementClass">
        <picture>
          <img [src]="imgUrl(selectedItem.fileName, selectedItem.id)" />
        </picture>
        <p>{{ translationString + selectedItem.id | translate }}</p>
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let item of items" [value]="item.id" class="big-select-option">
      <div [class]="'option ' + selectedClass(item.id) + ' ' + elementClass" (click)="valueChanged(item.id)">
        <picture>
          <img [src]="imgUrl(item.fileName, item.id)" />
        </picture>
        <p>{{ translationString + item.id | translate }}</p>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="options-container" *ngIf="!isMobile">
  <div
    *ngFor="let item of noPlaceHolderItems"
    [class]="'option ' + selectedClass(item.id) + ' ' + elementClass"
    (click)="valueChanged(item.id)"
    SimpleSmoothScroll
  >
    <picture>
      <img [src]="imgUrl(item.fileName, item.id)" />
    </picture>
    <p [innerHTML]="translationString + item.id | translate"></p>
  </div>
</div>
