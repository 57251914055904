import { VisitAdValoris } from './../../models/visitAdValoris';
import { Action } from '@ngrx/store';
import { Product } from '../../models/product';
import { Reduction } from '../../models/reduction';
import { Address } from '../../models/address';
import { Owner, Contact, UserForm, UserType, User } from '../../models/user';
import { Document } from '../../models/document';
import { Region } from '../../models/region';
import { Print } from '../../models/print';
import { Visit } from '../../models/visit';
import { VisitContact } from '../../models/visitContact';
import { Panel } from '../../models/paper';

export class ActionTypes {
  static SET_REGION = '[Order] SET_REGION';
  static SET_REGIONINFO = '[Order] SET_REGIONINFO';
  static SET_ESTATETYPE = '[Order] SET_ESTATETYPE';
  static SET_ESTATESTYLE = '[Order] SET_ESTATESTYLE';
  static SET_NUMBEROFAPPARTMENTS = '[Order] SET_NUMBEROFAPPARTMENTS';
  static SET_PRODUCTS = '[Order] SET_PRODUCTS';
  static SET_SERVICES = '[Order] SET_SERVICES';
  static SET_NBOFELEC = '[Order] SET_NBOFELEC';
  static SET_NBOFGAZ = '[Order] SET_NBOFGAZ';
  static SET_GAZCHOICE = '[Order] SET_GAZCHOICE';
  static SET_PAYMENTTYPE = '[Order] SET_PAYMENTTYPE';
  static SET_ACTETYPE = '[Order] SET_ACTETYPE';
  static TOGGLE_SENDBYMAIL = '[Order] TOGGLE_SENDBYMAIL';
  static SET_REMARKS = '[Order] SET_REMARKS';
  static SET_INVOICETO = '[Order] SET_INVOICETO';
  static SET_IMMOPACK = '[Order] SET_IMMOPACK';
  static SET_IMMOPACKIDS = '[Order] SET_IMMOPACKIDS';
  static SET_REDUCTIONS = '[Order] SET_REDUCTIONS';
  static TOGGLE_ONLINEPAYMENT = '[Order] TOGGLE_ONLINEPAYMENT';
  static SET_PROMOCODE = '[Order] SET_PROMOCODE';
  static TOGGLE_URGENCE = '[Order] TOGGLE_URGENCE';
  static TOGGLE_URGENCE_PRO = '[Order] TOGGLE_URGENCE_PRO';
  static SET_ADDRESS = '[Order] SET_ADDRESS';
  static SET_ZIPCODE = '[Order] SET_ZIPCODE';
  static SET_OWNER = '[Order] SET_OWNER';
  static SET_CONTACT = '[Order] SET_CONTACT';
  static SET_CONTACTS = '[Order] SET_CONTACTS';
  static SET_USERFORM = '[Order] SET_USERFORM';
  static SET_DATEBUTOIR = '[Order] SET_DATEBUTOIR';
  static SET_DOCUMENT = '[Order] SET_DOCUMENT';
  static RESET_ORDERSTATE = '[Order] RESET_ORDERSTATE';
  static SET_NEWUSERTYPE = '[Order] SET_NEWUSERTYPE';
  static COPY_CONTACT = '[Order] COPY_CONTACT';
  static SET_GOGETKEYS = '[Order] SET_GOGETKEYS';
  static SET_COMMISSION = '[Order] SET_COMMISSION';
  static SET_CONTACTCHOICE = '[Order] SET_CONTACTCHOICE';
  static SET_NN = '[Order] SET_NN';
  static SET_PROMOCODEID = '[Order] SET_PROMOCODEID';
  static SET_PEBNB = '[Order] SET_PEBNB';
  static SET_PACKID = '[Order] SET_PACKID';
  static SET_PACKNAME = '[Order] SET_PACKNAME';
  static SET_NBOFISO = '[Order] SET_NBOFISO';
  static SET_REASON = '[Order] SET_REASON';
  static SET_CLIENTTYPE = '[Order] SET_CLIENTTYPE';
  static SET_IMMOORCTRL = '[Order] SET_IMMOORCTRL';
  static SET_VENTETYPE = '[Order] SET_VENTETYPE';
  static SET_VENTEINFO = '[Order] SET_VENTEINFO';
  static SET_PARUTIONS = '[Order] SET_PARUTIONS';
  static SET_PANELS = '[Order] SET_PANELS';
  static SET_PANELSINFO = '[Order] SET_PANELSINFO';
  static SET_PARUTION_LINKS = '[Order] SET_PARUTION_LINKS';
  static SET_PARUTION_COMMENT = '[Order] SET_PARUTION_COMMENT';
  static SET_VISITS = '[Order] SET_VISITS';
  static SET_VISITCONTACT = '[Order] SET_VISITCONTACT';
  static SET_VISITADVALORIS = '[Order] SET_VISITADVALORIS';
  static RESET_PACK = '[Order] RESET_PACK';
  static SET_SPLIT_INVOICE = '[Order] SET_SPLIT_INVOICE';
  static SET_AGENCY_PRODUCTS = '[Order] SET_AGENCY_PRODUCTS';
  static SET_ASKFOREXPERTISE = '[Order] SET_ASKFOREXPERTISE';
  static SET_ASKFOROFFER = '[Order] SET_ASKFOROFFER';
  static SET_ADDRESSKEY = '[Order] SET_ADDRESSKEY';

  static SET_GUID = '[User] SET_GUID';
  static SET_TOKEN = '[User] SET_TOKEN';
  static SET_USERINFO = '[User] SET_USERINFO';
  static SET_ISLOGGEDIN = '[User] SET_ISLOGGEDIN';
  static RESET_USERINFO = '[User] RESET_USERINFO';
}

export class SetRegion implements Action {
  public type = ActionTypes.SET_REGION;
  constructor(public payload: number) {}
}
export class SetRegionInfo implements Action {
  public type = ActionTypes.SET_REGIONINFO;
  constructor(public payload: Region) {}
}
export class SetEstateType implements Action {
  public type = ActionTypes.SET_ESTATETYPE;
  constructor(public payload: number) {}
}
export class SetEstateStyle implements Action {
  public type = ActionTypes.SET_ESTATESTYLE;
  constructor(public payload: number) {}
}
export class SetNumberOfAppartments implements Action {
  public type = ActionTypes.SET_NUMBEROFAPPARTMENTS;
  constructor(public payload: number) {}
}
export class SetServices implements Action {
  public type = ActionTypes.SET_SERVICES;
  constructor(public payload: number[]) {}
}
export class SetProducts implements Action {
  public type = ActionTypes.SET_PRODUCTS;
  constructor(public payload: Product[]) {}
}
export class SetNbOfElec implements Action {
  public type = ActionTypes.SET_NBOFELEC;
  constructor(public payload: number) {}
}
export class SetNbOfGaz implements Action {
  public type = ActionTypes.SET_NBOFGAZ;
  constructor(public payload: number) {}
}
export class SetGazChoice implements Action {
  public type = ActionTypes.SET_GAZCHOICE;
  constructor(public payload: number) {}
}
export class SetPaymentType implements Action {
  public type = ActionTypes.SET_PAYMENTTYPE;
  constructor(public payload: string) {}
}
export class SetActeType implements Action {
  public type = ActionTypes.SET_ACTETYPE;
  constructor(public payload: string) {}
}
export class ToggleSendByMail implements Action {
  public type = ActionTypes.TOGGLE_SENDBYMAIL;
  constructor(public payload: boolean) {}
}
export class SetRemarks implements Action {
  public type = ActionTypes.SET_REMARKS;
  constructor(public payload: string) {}
}
export class SetInvoiceTo implements Action {
  public type = ActionTypes.SET_INVOICETO;
  constructor(public payload: string) {}
}
export class SetImmoPack implements Action {
  public type = ActionTypes.SET_IMMOPACK;
  constructor(public payload: number) {}
}
export class SetImmoPackIds implements Action {
  public type = ActionTypes.SET_IMMOPACKIDS;
  constructor(public payload: number[]) {}
}
export class SetReductions implements Action {
  public type = ActionTypes.SET_REDUCTIONS;
  constructor(public payload: Reduction[]) {}
}
export class ToggleOnlinePayment implements Action {
  public type = ActionTypes.TOGGLE_ONLINEPAYMENT;
  constructor(public payload: boolean) {}
}
export class SetPromoCode implements Action {
  public type = ActionTypes.SET_PROMOCODE;
  constructor(public payload: string) {}
}
export class ToggleUrgence implements Action {
  public type = ActionTypes.TOGGLE_URGENCE;
  constructor(public payload: boolean) {}
}
export class ToggleUrgencePro implements Action {
  public type = ActionTypes.TOGGLE_URGENCE_PRO;
  constructor(public payload: boolean) {}
}
export class SetAddress implements Action {
  public type = ActionTypes.SET_ADDRESS;
  constructor(public payload: Address) {}
}
export class SetZipCode implements Action {
  public type = ActionTypes.SET_ZIPCODE;
  constructor(public payload: string) {}
}
export class SetOwner implements Action {
  public type = ActionTypes.SET_OWNER;
  constructor(public payload: Owner) {}
}
export class SetContact implements Action {
  public type = ActionTypes.SET_CONTACT;
  constructor(public payload: Contact) {}
}
export class SetContacts implements Action {
  public type = ActionTypes.SET_CONTACTS;
  constructor(public payload: Contact[]) {}
}
export class SetUserForm implements Action {
  public type = ActionTypes.SET_USERFORM;
  constructor(public payload: UserForm) {}
}
export class SetDateButoir implements Action {
  public type = ActionTypes.SET_DATEBUTOIR;
  constructor(public payload: Date) {}
}
export class SetDocument implements Action {
  public type = ActionTypes.SET_DOCUMENT;
  constructor(public payload: Document) {}
}
export class ResetOrderState implements Action {
  public type = ActionTypes.RESET_ORDERSTATE;
  constructor(public payload) {}
}
export class SetNewUserType implements Action {
  public type = ActionTypes.SET_NEWUSERTYPE;
  constructor(public payload: UserType) {}
}
export class CopyContact implements Action {
  public type = ActionTypes.COPY_CONTACT;
  constructor(public payload: any) {}
}
export class SetGoGetKeys implements Action {
  public type = ActionTypes.SET_GOGETKEYS;
  constructor(public payload: boolean) {}
}
export class SetCommission implements Action {
  public type = ActionTypes.SET_COMMISSION;
  constructor(public payload: number) {}
}
export class SetContactChoice implements Action {
  public type = ActionTypes.SET_CONTACTCHOICE;
  constructor(public payload: string) {}
}
export class SetGuid implements Action {
  public type = ActionTypes.SET_GUID;
  constructor(public payload: string) {}
}
export class SetUserInfo implements Action {
  public type = ActionTypes.SET_USERINFO;
  constructor(public payload: User) {}
}
export class ResetUserInfo implements Action {
  public type = ActionTypes.RESET_USERINFO;
  constructor(public payload: User) {}
}
export class SetNN implements Action {
  public type = ActionTypes.SET_NN;
  constructor(public payload: string) {}
}
export class SetPromoCodeID implements Action {
  public type = ActionTypes.SET_PROMOCODEID;
  constructor(public payload: string) {}
}
export class SetPebNb implements Action {
  public type = ActionTypes.SET_PEBNB;
  constructor(public payload: string) {}
}
export class SetPackID implements Action {
  public type = ActionTypes.SET_PACKID;
  constructor(public payload: string) {}
}
export class SetPackName implements Action {
  public type = ActionTypes.SET_PACKNAME;
  constructor(public payload: string) {}
}
export class SetNbOfIso implements Action {
  public type = ActionTypes.SET_NBOFISO;
  constructor(public payload: number) {}
}

export class SetReason implements Action {
  public type = ActionTypes.SET_REASON;
  constructor(public payload: number) {}
}

export class SetClientType implements Action {
  public type = ActionTypes.SET_CLIENTTYPE;
  constructor(public payload: number) {}
}

export class SetImmoOrCtrl implements Action {
  public type = ActionTypes.SET_IMMOORCTRL;
  constructor(public payload: number) {}
}

export class SetVenteType implements Action {
  public type = ActionTypes.SET_VENTETYPE;
  constructor(public payload: string) {}
}

export class SetVenteInfo implements Action {
  public type = ActionTypes.SET_VENTEINFO;
  constructor(public payload: any) {}
}

export class SetParutions implements Action {
  public type = ActionTypes.SET_PARUTIONS;
  constructor(public payload: Print[]) {}
}

export class SetPanels implements Action {
  public type = ActionTypes.SET_PANELS;
  constructor(public payload: Panel[]) {}
}

export class SetPanelsInfo implements Action {
  public type = ActionTypes.SET_PANELSINFO;
  constructor(public payload: string) {}
}

export class SetParutionFileLinks implements Action {
  public type = ActionTypes.SET_PARUTION_LINKS;
  constructor(public payload: string[]) {}
}

export class SetParutionComment implements Action {
  public type = ActionTypes.SET_PARUTION_COMMENT;
  constructor(public payload: string) {}
}

export class SetVisits implements Action {
  public type = ActionTypes.SET_VISITS;
  constructor(public payload: Visit[]) {}
}

export class SetVisitContact implements Action {
  public type = ActionTypes.SET_VISITCONTACT;
  constructor(public payload: VisitContact) {}
}

export class SetVisitAdValoris implements Action {
  public type = ActionTypes.SET_VISITADVALORIS;
  constructor(public payload: VisitAdValoris) {}
}

export class ResetPack implements Action {
  public type = ActionTypes.RESET_PACK;
  constructor(public payload: any) {}
}

export class SetSplitInvoice implements Action {
  public type = ActionTypes.SET_SPLIT_INVOICE;
  constructor(public payload: boolean) {}
}

export class SetAskForExpertise implements Action {
  public type = ActionTypes.SET_ASKFOREXPERTISE;
  constructor(public payload: boolean) {}
}

export class SetAskForOffer implements Action {
  public type = ActionTypes.SET_ASKFOROFFER;
  constructor(public payload: boolean) {}
}

export class SetAgencyProducts implements Action {
  public type = ActionTypes.SET_AGENCY_PRODUCTS;
  constructor(public payload: Product[]) {}
}

export class SetToken implements Action {
  public type = ActionTypes.SET_TOKEN;
  constructor(public payload: string) {}
}

export class SetAddressKey implements Action {
  public type = ActionTypes.SET_ADDRESSKEY;
  constructor(public payload: { id: string; price: number }) {}
}

export type Actions =
  | SetRegion
  | SetRegionInfo
  | SetEstateType
  | SetEstateStyle
  | SetNumberOfAppartments
  | SetServices
  | SetProducts
  | SetPanels
  | SetNbOfElec
  | SetPaymentType
  | SetActeType
  | ToggleSendByMail
  | SetRemarks
  | SetInvoiceTo
  | SetImmoPack
  | SetImmoPackIds
  | SetReductions
  | ToggleOnlinePayment
  | ToggleUrgence
  | ToggleUrgencePro
  | SetPromoCode
  | SetAddress
  | SetZipCode
  | SetOwner
  | SetContact
  | SetContacts
  | SetUserForm
  | SetDateButoir
  | SetDocument
  | ResetOrderState
  | SetNewUserType
  | CopyContact
  | SetGoGetKeys
  | SetCommission
  | SetContactChoice
  | SetGuid
  | SetUserInfo
  | ResetUserInfo
  | SetNN
  | SetPromoCodeID
  | SetGazChoice
  | SetPebNb
  | SetNbOfIso
  | SetReason
  | SetClientType
  | SetVenteType
  | SetVenteInfo
  | SetParutions
  | SetParutionFileLinks
  | SetVisits
  | SetVisitContact
  | SetVisitAdValoris
  | ResetPack
  | SetSplitInvoice
  | SetAgencyProducts
  | SetImmoOrCtrl
  | SetAskForExpertise
  | SetToken
  | SetAddressKey
  | SetPanelsInfo
  | SetAskForOffer;
