import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { User, Owner, Contact, UserForm, UserType } from '../core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';

import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import * as PRODUCTS from '../core/data/products';

import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { MatStepper } from '@angular/material/stepper';
import { MatCheckbox } from '@angular/material/checkbox';
import { projectID } from '../core/data/theme-config';

import PlaceResult = google.maps.places.PlaceResult;

import {
  SetAddress,
  SetOwner,
  SetContact,
  SetUserForm,
  SetDateButoir,
  SetNewUserType,
  SetGoGetKeys,
  SetContactChoice,
  SetInvoiceTo,
  SetVenteInfo,
  SetRegion,
  SetEstateStyle,
  SetAddressKey,
  SetContacts
} from '../core/store/order';
import { Address } from '../core/models/address';
import { newUserTypes } from '../core/data/newUserTypes';
import { debounceTime, map, startWith, switchMap, filter } from 'rxjs/operators';
import { ContactTypes } from '../core/enums/contact.enum';
import { SetStep2Valid } from '../core/store/ui';
import { filterNewUserTypes, step2HideDateButoir } from '../core/data/theme-config';
import { LoggerService } from '../core/services/logger.service';
import { VenteInfo } from '../core/models/venteInfo';
import { DataService } from '../core/services/data.service';
import { ClientOrdersService } from '../client-space/services/client-orders.service';
import { CompanyEmployeeDTO } from '../core/models/companyEmployeeDTO';
import { HttpClient } from '@angular/common/http';
import { OfficeAddressDTO } from '../core/models/officeAddress';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  assetsFolder = 'assets/images/';
  debouncer: any;
  codecountry = '+32';
  mask = '0000/000000';
  gsmholder = '0412/345678';
  step2FormGroup: FormGroup;
  minDate = new Date();
  user: User;
  userForm: UserForm;
  gsm: string;
  address: Address;
  owner: Owner;
  contact: Contact;
  newUserTypes: { id: UserType; txt: string; fileName: string }[];
  contactTypes = ContactTypes;
  userTypes = UserType;
  contactChoice: string;
  newUserType: UserType;
  userType: UserType;
  isValid: boolean;
  dateButoire: Date;
  region: number;
  officeAddresses: OfficeAddressDTO[];
  pResult: PlaceResult;
  vatMightBeInvalid = false;

  step2HideDateButoir = step2HideDateButoir;

  filterNewUserTypes = filterNewUserTypes;

  $ownerSub: Subscription;
  $userSub: Subscription;
  $venteInfoSub: Subscription;
  $cadastreSub: Subscription;
  $goGetKeys: Observable<boolean>;
  $nbOfApp: Observable<number>;

  @Input() stepper: MatStepper;

  $isLoggedIn: Observable<boolean>;
  $dateButoir: Observable<Date>;
  $newUserType: Observable<UserType>;
  $contact: Observable<Contact>;
  $contactChoice: Observable<string>;
  $owner: Observable<Owner>;
  $values: Observable<any>;
  $venteType: Observable<number>;
  $venteInfo: Observable<VenteInfo>;
  $zipCode: Observable<string>;

  $isInstallateur: Observable<boolean>;
  $isParticulier: Observable<boolean>;

  $consumerAddresses: Observable<Address[]>;
  $addressKeyId: Observable<string>;

  elecAgentControl = new FormControl();
  $elecAgents: Observable<CompanyEmployeeDTO[]>;
  $filteredElecAgents: Observable<CompanyEmployeeDTO[]>;

  cadastreFG: FormGroup;
  venteFG: FormGroup;

  isAvailableZipFlanders$: Observable<boolean>;
  isAnversZip$: Observable<boolean>;

  venteInfo = {
    street: '',
    num: '',
    city: '',
    zip: '',
    division: '',
    section: '',
    numCadastre: '',
    rcni: '',
    ventePub: {
      venteStatut: {
        id: 0,
        name: ''
      }
    }
  };

  userTitles = [
    'Madame',
    'Monsieur',
    'Mademoiselle',
    'Madame et Monsieur',
    'Messieurs',
    'Mesdames',
    'Mesdemoiselles',
    'Professeur',
    'Docteur',
    'Maître',
    'Administration provisoire',
    'Indivision',
    'Succession',
    'Veuve',
    'Veuf'
  ];

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    public dataService: DataService,
    private _formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private clientService: ClientOrdersService,
    private http: HttpClient,
    private logger: LoggerService
  ) {
    this.$isLoggedIn = this.store.select(orderSelector.getIsLoggedIn);

    this.$contact = this.store.select(orderSelector.getContact);

    this.$contactChoice = this.store.select(orderSelector.getContactChoice);
    this.$contactChoice.subscribe(c => (this.contactChoice = c));
    this.$newUserType = this.store.select(orderSelector.getUserType);
    this.store.select(orderSelector.getUserInfo).subscribe(u => (this.user = u));
    this.store.select(orderSelector.getUserForm).subscribe(u => (this.userForm = u));
    this.$owner = this.store.select(orderSelector.getOwner);
    this.$newUserType.subscribe(ut => (this.newUserType = ut));
    this.store.select(orderSelector.getUserType).subscribe(ut => (this.userType = ut));
    this.$venteType = this.store.select(orderSelector.getVenteType);
    this.$zipCode = this.store.select(orderSelector.getAddress).pipe(map(ad => ad.zip));
    this.$consumerAddresses = this.store.select(orderSelector.getConsumerAddresses);
    this.$addressKeyId = this.store.select(orderSelector.getAddressKey);
    this.$nbOfApp = this.store.select(orderSelector.getNbOfAppartments);
    this.$isInstallateur = this.authService.isInstallateur;
    this.$isParticulier = this.authService.isParticulier;
    this.$elecAgents = this.clientService
      .getElecAgents()
      .pipe(map(agents => agents.sort((a, b) => a.Name.localeCompare(b.Name))));

    this.$filteredElecAgents = this.elecAgentControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value))
    );

    this.isAvailableZipFlanders$ = this.$zipCode.pipe(
      map(code => {
        let res = true;
        if (+code > 999 && +code < 10000) {
          if (
            this.region === 2 &&
            !(
              (+code >= 1500 && +code < 2000) ||
              //(+code >= 2050 && +code <= 2160) ||
              //(+code >= 2200 && +code <= 2240) ||
              // +code == 2260 ||
              //+code == 2270 ||
              //+code == 2288 ||
              //+code == 2290 ||
              //(+code >= 2500 && +code <= 2890) ||
              (+code >= 3000 && +code <= 3499)
            )
            //(+code >= 9000 && +code < 10000)
          ) {
            res = false;
          }
        }
        return res;
      })
    );

    this.isAnversZip$ = this.$zipCode.pipe(
      map(code => {
        let res = false;
        if (+code >= 2000 && +code < 3000) {
          res = true;
        }
        return res;
      })
    );
    this.$venteInfo = this.store.select(orderSelector.getVenteInfo);
    this.store.select(orderSelector.getRegion).subscribe(re => (this.region = re));
    this.$venteInfo.subscribe(v => (this.venteInfo = v));

    this.$owner.subscribe(o => {
      this.owner = o;
    });

    this.venteFG = this._formBuilder.group({
      street: [{ value: this.venteInfo.street, disabled: false }],
      num: [{ value: this.venteInfo.num, disabled: false }],
      city: [{ value: this.venteInfo.city, disabled: false }],
      zip: [{ value: this.venteInfo.zip, disabled: false }]
    });

    this.cadastreFG = this._formBuilder.group({
      division: [{ value: this.venteInfo.division, disabled: false }],
      section: [{ value: this.venteInfo.section, disabled: false }],
      numCadastre: [{ value: this.venteInfo.numCadastre, disabled: false }],
      rcni: [{ value: this.venteInfo.rcni, disabled: false }]
    });

    this.$venteInfoSub = this.venteFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = { ...this.venteInfo };
      this.venteInfo.street = values.street;
      this.venteInfo.num = values.num;
      this.venteInfo.city = values.city;
      this.venteInfo.zip = values.zip;
      /* this.venteInfo.statusVentePub = values.venteStatut;
      this.venteInfo.venteDate = values.venteDate; */

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.$cadastreSub = this.cadastreFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = { ...this.venteInfo };
      this.venteInfo.division = values.division;
      this.venteInfo.section = values.section;
      this.venteInfo.numCadastre = values.numCadastre;
      this.venteInfo.rcni = values.rcni;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.$contact.subscribe(c => (this.contact = c));
    this.store.select(orderSelector.getAddress).subscribe(a => (this.address = a));

    combineLatest(this.$isLoggedIn, this.$newUserType, this.$contactChoice, (bool, newUserType, contactChoice) => ({
      bool,
      newUserType,
      contactChoice
    })).subscribe(({ bool, newUserType, contactChoice }) => {
      const isConnectedPart = bool && this.userType === UserType.Particulier;
      const isNewUserPart = !bool && newUserType === UserType.Particulier;
      const isParticulier = isNewUserPart || isConnectedPart;
      const notLogAndNotPart = !bool && !isParticulier;
      // this.logger.log('connected?', bool);
      // this.logger.log('isParticulier', isParticulier);
      if (!bool && isParticulier) {
        this.step2FormGroup = this._formBuilder.group({
          user: this._formBuilder.group({
            title: [
              {
                value: this.userForm.title,
                disabled: false
              }
            ],
            firstName: [
              {
                value: this.userForm.firstname,
                disabled: false
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.userForm.lastname,
                disabled: false
              },
              Validators.required
            ],
            gsm: [
              {
                value: this.userForm.gsm,
                disabled: false
              },
              Validators.required
            ],
            indicator: [
              {
                value: this.userForm.indicator === '' ? this.codecountry : this.userForm.indicator,
                disabled: false
              },
              Validators.required
            ],
            indicatorphone: [
              {
                value: this.userForm.indicatorphone === '' ? this.codecountry : this.userForm.indicatorphone,
                disabled: false
              },
              Validators.required
            ],
            phone: [
              {
                value: this.userForm.phone,
                disabled: false
              }
            ],
            email: [
              {
                value: this.userForm.email,
                disabled: false
              },
              [Validators.required, Validators.email],
              this.isEmailUnique.bind(this)
            ],
            companyname: [
              {
                value: this.userForm.companyname,
                disabled: false
              }
            ],
            usertva: [
              {
                value: this.userForm.tva,
                disabled: false
              }
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderZip: [this.address.zip, [Validators.required, this.inputZipCode.bind(this)]],
            orderCity: [this.address.city, Validators.required],
            orderBoxNumber: [this.address.boxNumber],
            orderApartmentNumber: [this.address.apartmentNumber]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              }
            ],
            gsm: [
              {
                value: this.contact.gsm,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            contacts: this._formBuilder.array([])
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$userSub = this.step2FormGroup
          .get('user')
          .valueChanges.pipe(debounceTime(500))
          .subscribe(values => {
            const user = new UserForm();
            user.title = values.title;
            user.firstname = values.firstName;
            user.lastname = values.lastName;
            user.phone = values.phone;
            user.gsm = values.gsm;
            user.indicator = values.indicator;
            user.indicatorphone = values.indicatorphone;
            user.email = values.email;
            user.commercialname = values.companyname;
            user.companyname = values.companyname;
            user.tva = values.usertva;
            user.address.street = this.address.street;
            user.address.number = this.address.number;
            user.address.zip = this.address.zip;
            user.address.city = this.address.city;
            user.address.boxNumber = this.address.boxNumber;
            user.address.apartmentNumber = this.address.apartmentNumber;
            this.store.dispatch(new SetUserForm(user));
          });
      } else if (notLogAndNotPart) {
        this.step2FormGroup = this._formBuilder.group({
          user: this._formBuilder.group({
            title: [
              {
                value: this.userForm.title,
                disabled: bool
              }
            ],
            firstName: [
              {
                value: this.userForm.firstname,
                disabled: bool
              },
              Validators.required
            ],
            gsm: [
              {
                value: this.userForm.gsm,
                disabled: false
              },
              Validators.required
            ],
            indicator: [
              {
                value: this.userForm.indicator === '' ? this.codecountry : this.userForm.indicator,
                disabled: false
              },
              Validators.required
            ],
            indicatorphone: [
              {
                value: this.userForm.indicatorphone === '' ? this.codecountry : this.userForm.indicatorphone,
                disabled: false
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.userForm.lastname,
                disabled: bool
              },
              Validators.required
            ],
            phone: [
              {
                value: this.userForm.phone,
                disabled: bool
              }
            ],
            email: [
              {
                value: this.userForm.email,
                disabled: bool
              },
              [Validators.required, Validators.email],
              this.isEmailUnique.bind(this)
            ],
            tva: [
              {
                value: this.userForm.tva,
                disabled: !notLogAndNotPart
              }
            ],
            userStreet: [
              {
                value: this.userForm.address.street,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userNum: [
              {
                value: this.userForm.address.number,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userZip: [
              {
                value: this.userForm.address.zip,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userCity: [
              {
                value: this.userForm.address.city,
                disabled: !notLogAndNotPart
              },
              Validators.required
            ],
            userBoxNumber: [
              {
                value: this.userForm.address.boxNumber,
                disabled: !notLogAndNotPart
              }
            ],
            userApartmentNumber: [
              {
                value: this.userForm.address.apartmentNumber,
                disabled: !notLogAndNotPart
              }
            ]
          }),
          owner: this._formBuilder.group({
            title: [
              {
                value: this.owner.title,
                disabled: isParticulier
              }
            ],
            firstName: [
              {
                value: this.owner.firstname,
                disabled: isParticulier
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.owner.lastname,
                disabled: isParticulier
              },
              Validators.required
            ],
            gsm: [
              {
                value: this.owner.gsm,
                disabled: isParticulier
              },
              Validators.required
            ],
            indicator: [
              {
                value: this.owner.indicator === '' ? this.codecountry : this.owner.indicator,
                disabled: false
              },
              Validators.required
            ],
            indicatorphone: [
              {
                value: this.owner.indicatorphone === '' ? this.codecountry : this.owner.indicatorphone,
                disabled: false
              },
              Validators.required
            ],
            phone: [
              {
                value: this.owner.phone,
                disabled: isParticulier
              }
            ],
            email: [
              {
                value: this.owner.email,
                disabled: isParticulier
              },
              Validators.email,
              this.isEmailUnique.bind(this)
            ],
            companyname: [
              {
                value: this.owner.companyname,
                disabled: false
              }
            ],
            usertva: [
              {
                value: this.owner.tva,
                disabled: false
              }
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            boxNumber: [this.address.boxNumber],
            apartmentNumber: [this.address.apartmentNumber],
            orderZip: [this.address.zip, [Validators.required, this.inputZipCode.bind(this)]],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              }
            ],
            gsm: [
              {
                value: this.contact.gsm,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            contacts: this._formBuilder.array([])
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$ownerSub = this.step2FormGroup
          .get('owner')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const owner = new Owner();
            owner.firstname = values.firstName;
            owner.lastname = values.lastName;
            owner.phone = values.phone;
            owner.gsm = values.gsm;
            owner.indicator = values.indicator;
            owner.indicatorphone = values.indicatorphone;
            owner.email = values.email;
            owner.companyname = values.companyname;
            owner.commercialname = values.companyname;
            owner.tva = values.usertva;
            owner.title = values.title;
            this.store.dispatch(new SetOwner(owner));
          });

        this.$userSub = this.step2FormGroup
          .get('user')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const user = new UserForm();
            user.title = values.title;
            user.firstname = values.firstName;
            user.lastname = values.lastName;
            user.phone = values.phone;
            user.gsm = values.gsm;
            user.indicator = user.indicator;
            user.indicatorphone = user.indicatorphone;
            user.email = values.email;
            user.commercialname = values.companyname;
            user.companyname = values.companyname;
            user.tva = values.tva;
            user.address.street = values.userStreet;
            user.address.number = values.userNum;
            user.address.zip = values.userZip;
            user.address.city = values.userCity;
            user.address.boxNumber = values.boxNumber;
            user.address.apartmentNumber = values.apartmentNumber;
            this.store.dispatch(new SetUserForm(user));
          });
      } else if (bool && !isParticulier) {
        // this.logger.log('this connected');
        this.step2FormGroup = this._formBuilder.group({
          owner: this._formBuilder.group({
            title: [
              {
                value: this.owner.title,
                disabled: isParticulier
              }
            ],
            firstName: [
              {
                value: this.owner.firstname,
                disabled: isParticulier
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.owner.lastname,
                disabled: isParticulier
              },
              Validators.required
            ],
            gsm: [
              {
                value: this.owner.gsm == '' ? ' ' : this.owner.gsm,
                disabled: isParticulier
              },
              Validators.required
            ],
            indicator: [
              {
                value: this.owner.indicator === '' ? this.codecountry : this.owner.indicator,
                disabled: false
              },
              Validators.required
            ],
            indicatorphone: [
              {
                value: this.owner.indicatorphone === '' ? this.codecountry : this.owner.indicatorphone,
                disabled: false
              },
              Validators.required
            ],
            phone: [
              {
                value: this.owner.phone,
                disabled: isParticulier
              }
            ],
            email: [
              {
                value: this.owner.email,
                disabled: isParticulier
              },
              Validators.email
            ],
            companyname: [
              {
                value: this.owner.companyname,
                disabled: false
              }
            ],
            usertva: [
              {
                value: this.owner.tva,
                disabled: false
              }
            ]
          }),
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderBoxNumber: [this.address.boxNumber],
            orderApartmentNumber: [this.address.apartmentNumber],
            orderZip: [this.address.zip, [Validators.required, this.inputZipCode.bind(this)]],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              }
            ],
            gsm: [
              {
                value: this.contact.gsm,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            contacts: this._formBuilder.array([])
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });

        this.$ownerSub = this.step2FormGroup
          .get('owner')
          .valueChanges.pipe(debounceTime(1000))
          .subscribe(values => {
            const owner = new Owner();
            owner.firstname = values.firstName;
            owner.lastname = values.lastName;
            owner.phone = values.phone;
            owner.gsm = values.gsm;
            owner.indicator = values.indicator;
            owner.indicatorphone = values.indicatorphone;
            owner.email = values.email;
            owner.companyname = values.companyname;
            owner.commercialname = values.companyname;
            owner.tva = values.usertva;
            owner.title = values.title;
            this.store.dispatch(new SetOwner(owner));
          });
      } else if (bool && isParticulier) {
        this.step2FormGroup = this._formBuilder.group({
          address: this._formBuilder.group({
            orderStreet: [this.address.street, Validators.required],
            orderNum: [this.address.number, Validators.required],
            orderBoxNumber: [this.address.boxNumber],
            orderApartmentNumber: [this.address.apartmentNumber],
            orderZip: [this.address.zip, [Validators.required, this.inputZipCode.bind(this)]],
            orderCity: [this.address.city, Validators.required]
          }),
          contact: this._formBuilder.group({
            firstName: [
              {
                value: this.contact.firstname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            lastName: [
              {
                value: this.contact.lastname,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            phone: [
              {
                value: this.contact.phone,
                disabled: contactChoice !== 'other'
              }
            ],
            gsm: [
              {
                value: this.contact.gsm,
                disabled: contactChoice !== 'other'
              },
              Validators.required
            ],
            contacts: this._formBuilder.array([])
          }),
          date: this._formBuilder.group({
            dateButoir: ['']
          })
        });
      }
      // this.logger.log(this.step2FormGroup);
      if (this.contactChoice === 'sameAsOwner') {
        this.$owner.subscribe(o => {
          const c = new Contact();
          c.firstname = o.firstname;
          c.lastname = o.lastname;
          c.phone = o.phone;
          c.gsm = o.gsm;
          this.store.dispatch(new SetContact(c as Contact));
        });
      }

      this.step2FormGroup
        .get('address')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          // this.logger.log('changes to address');
          const address = new Address();
          address.street = values.orderStreet;
          address.number = values.orderNum;
          address.zip = values.orderZip;
          address.city = values.orderCity;
          address.boxNumber = values.orderBoxNumber;
          address.apartmentNumber = values.orderApartmentNumber;
          this.store.dispatch(new SetAddress(address));
        });

      this.step2FormGroup
        .get('contact')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          this.logger.log('changes to contact');
          const contact = new Contact();
          contact.firstname = values.firstName;
          contact.lastname = values.lastName;
          contact.phone = values.phone;
          contact.gsm = values.gsm;
          this.store.dispatch(new SetContact(contact));
          this.store.dispatch(new SetContacts(values.contacts));

          const date = values.dateButoir as Date;
          if (date) {
            date.setHours(date.getHours() + 1);
            this.store.dispatch(new SetDateButoir(date));
          }
        });

      this.step2FormGroup
        .get('date')
        .valueChanges.pipe(debounceTime(1000))
        .subscribe(values => {
          // this.logger.log('changes to date');
          const date = values.dateButoir as Date;
          if (date) {
            date.setHours(date.getHours() + 1);
            this.store.dispatch(new SetDateButoir(date));
          }
        });
      this.step2FormGroup.statusChanges.subscribe(value => {
        this.store.dispatch(new SetStep2Valid(value === 'VALID'));
      });
    });

    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit() {
    this.$isLoggedIn.subscribe(bool => {
      // this.logger.log('connected?', bool);
      if (!bool) {
      }
    });

    this.$goGetKeys = this.store.select(orderSelector.getGoGeyKeys);
    this.newUserTypes = newUserTypes.filter(ut => this.filterNewUserTypes.every(fu => fu !== ut.id));
    this.$newUserType = this.store.select(orderSelector.getUserType);
    this.store.select(uiSelector.getStep2IsValid).subscribe(bool => (this.isValid = bool));
    this.$dateButoir = this.store.select(orderSelector.getDateButoir);
    this.$dateButoir.subscribe(d => (this.dateButoire = d));

    this.$zipCode.subscribe(zip => {
      if (+this.step2FormGroup.value.address.orderZip !== +zip) {
        this.step2FormGroup.get('address').patchValue({
          orderZip: zip
        });
      }
    });
    this.onZipChanges();
    this.step2FormGroup.updateValueAndValidity();
  }

  onZipChanges(): void {
    this.step2FormGroup
      .get('address')
      .valueChanges.pipe(debounceTime(500))
      .subscribe(val => {
        const code = val.orderZip;
        const valid = this.step2FormGroup.get('address.orderZip').valid;
        let region = -1;
        if (valid) {
          if (code >= 1000 && code <= 1299) {
            region = 1;
          } else if ((code >= 1500 && code <= 3999) || (code >= 8000 && code <= 9999)) {
            region = 2;
          } else if ((code >= 1300 && code <= 1499) || (code >= 4000 && code <= 7999)) {
            region = 0;
          }
          this.setRegion(region);
        }
      });
  }

  setRegion(id: number) {
    if (id === -1) {
      this.setEstateStyle(-1);
    }
    this.store.dispatch(new SetRegion(id));
  }

  setEstateStyle(style: number) {
    this.store.dispatch(new SetEstateStyle(style));
  }

  newUserTypeImgUrl(selectedId, fileName) {
    let url = this.assetsFolder;
    url += fileName;
    url += this.newUserType === selectedId ? '-selected.svg' : '.svg';
    return url;
  }

  setNewUserType(id: UserType) {
    if ((id === UserType.Particulier || id === UserType.Installateur) && this.contactChoice === 'keyAtAgency') {
      this.setContactChoice('sameAsOwner');
    }
    this.store.dispatch(new SetNewUserType(id));
  }

  filterWeekend(d: Date): boolean {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  logOut() {
    const logged = this.authService.logout();
    if (!logged) {
      this.stepper.reset();
    }
  }

  copyContact() {
    // e.preventDefault();
    if (this.owner.firstname || this.owner.lastname || this.owner.phone || this.owner.gsm) {
      // this.logger.log('owner');
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.owner.firstname,
        lastName: this.owner.lastname,
        phone: this.owner.phone,
        gsm: this.owner.gsm
      });
    } else {
      // this.logger.log(this.userForm.firstname);
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.userForm.firstname,
        lastName: this.userForm.lastname,
        phone: this.userForm.phone,
        gsm: this.userForm.gsm
      });
    }
  }

  setContactChoice(id: string) {
    this.store.dispatch(new SetContactChoice(id));
    if (id !== 'keyAtAgency') {
      this.dataService.removeService(PRODUCTS.DEPLACEMENT);
    }
    if (id === 'sameAsOwner') {
      this.copyContact();
      this.store.dispatch(new SetGoGetKeys(false));
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.contact.firstname,
        lastName: this.contact.lastname,
        phone: this.contact.phone,
        gsm: this.contact.gsm
      });
    } else if (id === 'other') {
      this.store.dispatch(new SetContact(new Contact()));
      this.store.dispatch(new SetGoGetKeys(false));
      this.step2FormGroup.get('contact').patchValue({
        firstName: this.contact.firstname,
        lastName: this.contact.lastname,
        phone: this.contact.phone,
        gsm: this.contact.gsm
      });
    } else if (id === 'keyAtAgency') {
      this.store.select(orderSelector.getAddress).subscribe(add => {
        if (add.street !== '' && add.zip !== '' && add.number !== '' && add.city !== '') {
          this.http
            .get<OfficeAddressDTO[]>(
              `https://certinergiesystems.azurewebsites.net/api/orderinfo/GetKeyAddresses?userId=${this.authService.userId}&orderAddress=${add.street} ${add.number} ${add.zip} ${add.city}`
            )
            .subscribe((adds: any) => {
              this.officeAddresses = adds.Data;
              if (this.officeAddresses && this.officeAddresses.length > 0) {
                const defaultOffice = this.officeAddresses[0];
                this.setOfficeDeplacement({
                  id: defaultOffice.AddressObj.AddressID,
                  price: defaultOffice.Price
                });
              }
            });
        }
      });
      this.store.dispatch(new SetGoGetKeys(true));
    }
    this.step2FormGroup.get('date').patchValue({
      dateButoir: this.dateButoire
    });
    if (this.pResult) {
      const addrObj: any = this.getFormattedAddress(this.pResult);
      this.step2FormGroup.get('address').patchValue({
        orderStreet: addrObj.street,
        orderNum: addrObj.num,
        orderCity: addrObj.city
      });
    }
  }

  toStep3() {
    if (this.isValid) {
      this.store.dispatch(new SetInvoiceTo('toProprio'));
    }
  }

  onAutocompleteSelected(pResult: PlaceResult, type: string) {
    this.pResult = pResult;
    const address = new Address();

    const addrObj: any = this.getFormattedAddress(pResult);

    address.number = addrObj.num;
    address.street = addrObj.street;
    address.zip = addrObj.zip;
    if (!addrObj.zip) {
      address.zip = '';
    }
    address.city = addrObj.city;
    address.country = addrObj.country;
    address.lat = addrObj.lat;
    address.lng = addrObj.lng;

    setTimeout(() => {
      if (type === 'owner') {
        this.step2FormGroup.get('address').patchValue({
          orderStreet: address.street,
          orderNum: address.number,
          orderZip: address.zip,
          orderCity: address.city
        });
        this.store.dispatch(new SetAddress(address));
      } else if (type === 'user') {
        const user = { ...this.userForm };
        user.address = address;
        this.step2FormGroup.get('user').patchValue({
          userStreet: address.street,
          userNum: address.number,
          userZip: address.zip,
          userCity: address.city
        });

        this.store.dispatch(new SetUserForm(user));
      }
      this.step2FormGroup.markAsDirty();
      this.step2FormGroup.updateValueAndValidity();
      this.cdRef.detectChanges();
    }, 200);
  }

  getFormattedAddress(place) {
    const location_obj = {};
    for (const i in place.address_components) {
      if (place.address_components.hasOwnProperty(i)) {
        const item = place.address_components[i];
        location_obj['formatted_address'] = place.formatted_address;
        if (item['types'].indexOf('locality') > -1) {
          location_obj['city'] = item['long_name'];
        } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
          location_obj['admin_area_l1'] = item['short_name'];
        } else if (item['types'].indexOf('street_number') > -1) {
          location_obj['num'] = item['short_name'];
        } else if (item['types'].indexOf('route') > -1) {
          location_obj['street'] = item['long_name'];
        } else if (item['types'].indexOf('country') > -1) {
          location_obj['country'] = item['long_name'];
        } else if (item['types'].indexOf('postal_code') > -1) {
          location_obj['zip'] = item['short_name'];
        }
      }
    }
    if (place && place.geometry && place.geometry.location.lat) {
      location_obj['lat'] = place.geometry.location.lat();
    }
    if (place && place.geometry && place.geometry.location.lng) {
      location_obj['lng'] = place.geometry.location.lng();
    }
    return location_obj;
  }

  isEmailUnique(control: FormControl) {
    clearTimeout(this.debouncer);

    return new Promise((resolve, reject) => {
      this.debouncer = setTimeout(() => {
        this.authService.isEmailAlreadyInUse(control.value).subscribe(data => {
          if (data.Data) {
            this.logger.log('Email exists', data.Data);
            resolve({ isEmailAlreadyInUse: true });
          } else {
            this.logger.log(`🚀`);
            resolve(null);
          }
        });
      }, 1000);
    });
  }

  toggleDisplayTVA(checkbox: MatCheckbox) {
    this.logger.log(checkbox);
  }

  inputZipCode(control: AbstractControl): { [key: string]: any } | null {
    const pattern = new RegExp(/^(?:(?:[1-9])(?:\d{3}))$/);
    const code = control.value + '';
    const valid = code ? code.match(pattern) : false;
    return valid ? null : { isZipCode: { valid: false, value: code } };
  }

  selectStatusVentePub(item) {
    this.venteInfo = JSON.parse(JSON.stringify(this.venteInfo));
    this.venteInfo.ventePub.venteStatut = item;
    this.store.dispatch(new SetVenteInfo(this.venteInfo));
  }

  checkTva(data: any) {
    this.dataService.checkTva(data.target.value).subscribe(res => {
      this.vatMightBeInvalid = !res;
    });
  }

  setAddressKey(id: string, officeAddresses: OfficeAddressDTO[]) {
    const office = officeAddresses.find(off => off.AddressObj.AddressID === id);
    this.setOfficeDeplacement({ id: office.AddressObj.AddressID, price: office.Price });
  }

  setOfficeDeplacement(office: { id: string; price: number }) {
    this.store.dispatch(new SetAddressKey(office));
    this.dataService.removeService(PRODUCTS.DEPLACEMENT);
    setTimeout(() => {
      this.dataService.setService(PRODUCTS.DEPLACEMENT);
    }, 200);
  }

  get contactsArray(): FormArray {
    return this.step2FormGroup.get('contact.contacts') as FormArray;
  }

  addContacts() {
    this.contactsArray.push(this._formBuilder.group(new Contact()));
  }

  private _filter(value: string): Observable<CompanyEmployeeDTO[]> {
    if (typeof value !== 'string') {
      return of(null);
    }
    const filterValue = value.toLowerCase();

    return this.$elecAgents.pipe(map(agents => agents.filter(agent => agent.Name.toLowerCase().includes(filterValue))));
  }

  displayFn(employee: CompanyEmployeeDTO): string {
    return employee && employee.Name ? employee.Name : '';
  }
  changeprefix(event) {
    switch (event.value) {
      case '+32': {
        this.mask = '0000/000000';
        this.gsmholder = '0412/345678';
        break;
      }
      case '+31': {
        this.mask = '000 000 0000';
        this.gsmholder = '021 234 5678';
        break;
      }
      case '+33': {
        this.mask = '00 00 00 00 00';
        this.gsmholder = '06 12 34 56 78';
        break;
      }
      case '+352': {
        this.mask = '0000 000 000';
        this.gsmholder = '0623 456 789';
        break;
      }
      case '+49': {
        this.mask = '000 0 0000000';
        this.gsmholder = '017 0 7654321';
        break;
      }
      default: {
        this.mask = '0000/000000';
        this.gsmholder = '0412/345678';
        break;
      }
    }
  }
}
