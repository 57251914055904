export const headerMenu = {
  ['fr']: [],
  ['nl']: [],
  ['en']: [],
  ['de']: [],
  ['fr-desktop']: [],
  ['nl-desktop']: [],
  ['en-desktop']: [],
  ['de-desktop']: []
};

export const contactLinks = {
  ['fr']: 'https://www.certinergie.be/fr/contact/',
  ['nl']: 'https://www.certinergie.be/nl/contact-en-afspraak/',
  ['en']: 'https://www.certinergie.be/en/contact-and-appointment/',
  ['de']: 'https://www.certinergie.be/en/contact-and-appointment/'
};
