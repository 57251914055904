import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggerService } from './logger.service';
import { Address } from '../models/address';
import { projectID } from '../data/theme-config';
import * as PRODUCTS from '../../core/data/products';
import { SendBrochure } from '../models/brochure';
import { environment as ENV } from 'src/environments/environment';
import { VisitZyva } from '../models/visitZyva';
import { ZyvaHelper } from './zyva.helper';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {
  mathieu = 'mh@certinergie.be';
  guido = 'guido@certinergie.be';
  geoffroy = 'gdb@certinergie.be';
  frederic = 'frederic.lemaire@certinergie.be';
  nabil = 'nabil@certinergie.be';
  apiURL = 'https://systems.certinergie.be/api/mailsending/sendMail';
  apiWithTemplateURL = 'https://systems.certinergie.be/api/EmailWithTemplate/SendMail';
  certiEmail = 'info@certinergie.be';
  graphisme = 'graphisme@certinergie.be';
  zyvaMail = "hello@zyva.immo"
  notaryEmail =
    projectID === 'hainaut'
      ? 'mons@maisondesnotaires.be'
      : projectID === 'namur'
        ? 'maison.notaires.namur@belnot.be'
        : projectID === 'nvn'
          ? 'not@nvn.be'
          : 'nabil@certinergie.be';

  constructor(private http: HttpClient, private logger: LoggerService) { }

  getListHtml(links: string[]) {
    if (!links || links.length === 0) {
      return '';
    }
    const formattedLinks = links.map(link => `<li>${link}</li>`).join('');
    return `<ul><li>Links: <ul>${formattedLinks}</ul></li></ul>`;
  }

  public sendDevis(
    value: {
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      remarks: string;
      userCity?: string;
      userNum?: string;
      userStreet?: string;
      userZip?: string;
    },
    title?: string,
    links?: string[],
    serviceId?: number,
    toFred = false
  ): Observable<any> {
    this.logger.log('email data: ', value);
    this.logger.log('email data: ', title);
    this.logger.log('email data: ', links);
    const data = {
      user: {
        name: `${value.firstName} ${value.lastName}`,
        mail: value.email,
        phone: value.phone
      },
      estate: {
        street: value.userStreet,
        zip: value.userZip,
        numbox: value.userNum,
        city: value.userCity
      },
      message: {
        subject: 'Demande de devis',
        message: value.remarks.replace(new RegExp('\n', 'g'), '<br />')
      },
      title: title
    };

    const subject = `${data.message.subject} ${data.title ? data.title : ''}`;

    let message = `
      <h2>${subject}</h2>
      <p>Nom: ${data.user.name}</p>
      <p>Email: ${data.user.mail}</p>
      <p>Tel: ${data.user.phone}</p>
      ${data.estate.street && data.estate.street !== ''
        ? `<p>Adresse: ${data.estate.street} ${data.estate.numbox} ${data.estate.zip} ${data.estate.city} </p>`
        : ''
      }
      ${links && links.length > 0 ? this.getListHtml(links) : ''}
      <p>${data.message.message}</p>
    `;
    if (!toFred) {
      message += `<p><small>Une copie de cet email a été envoyée à ${this.certiEmail}</small></p>`;
    }

    this.sendMail(data.user.mail, 'Confirmation ' + subject, message).subscribe();
    const emailDepartment = this.getEmailDepartment(serviceId);
    if (emailDepartment) {
      this.sendMail(emailDepartment, subject, message).subscribe();
    }
    if (serviceId === PRODUCTS.NETTJARDIN || serviceId === PRODUCTS.NETTMAISON) {
      this.sendMail('jerome.paquot@xlg.eu', subject, message).subscribe();
    }

    return this.sendMail(toFred ? this.frederic : this.certiEmail, subject, message);
  }

  getEmailDepartment(serviceId: number) {
    switch (serviceId) {
      case PRODUCTS.RESP_PEB:
        return 'rpeb@certinergie.be';
      case PRODUCTS.ELEC_NONDOM_MAX125A:
        return 'mh@certinergie.be';
      case PRODUCTS.ELEC_NONDOM_OVER125A:
        return 'mh@certinergie.be';
      case PRODUCTS.AMIANTE:
        return 'mh@certinergie.be';
      case PRODUCTS.NETTJARDIN:
        return 'simon.desmedt@xlg.eu';
      case PRODUCTS.NETTMAISON:
        return 'simon.desmedt@xlg.eu';
      case PRODUCTS.ZYVA:
        return 'hello@zyva.immo';
      default:
        return 'nabil@certinergie.be';
    }
  }

  public sendNotaryRequest(value: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    subject: string;
    address: Address;
    message: string;
  }): Observable<any> {
    this.logger.log('email data: ', value);
    const data = {
      user: {
        name: `${value.firstName} ${value.lastName}`,
        mail: value.email,
        phone: value.phone
      },
      estate: {
        street: value.address.street,
        zip: value.address.zip,
        numbox: value.address.number,
        city: value.address.city
      },
      message: {
        subject: value.subject,
        message: value.message
      }
    };

    const subject = data.message.subject;
    let message =
      '<h1>' +
      data.message.subject +
      '</h1>' +
      'Nom: ' +
      data.user.name +
      ' <br/> ' +
      'Email: ' +
      data.user.mail +
      ' <br/> ' +
      'Tel: ' +
      data.user.phone +
      ' <br/> ' +
      'Adresse: ' +
      data.estate.street +
      ' ' +
      data.estate.numbox +
      ' ' +
      data.estate.zip +
      ' ' +
      data.estate.city +
      ' <br/> <br/> ' +
      data.message.message;

    message += '<br/><br/>';

    this.sendMail(data.user.mail, 'Confirmation ' + subject, message).subscribe();
    this.sendMail(this.certiEmail, '[COPY - NO ACTION REQUIRED] ' + subject, message).subscribe();

    return this.sendMail(this.notaryEmail, subject, message);
  }

  public sendRequest(value: {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    subject: string;
    message: string;
  }): Observable<any> {
    this.logger.log('email data: ', value);
    const data = {
      user: {
        name: `${value.firstName} ${value.lastName}`,
        mail: value.email,
        userId: value.userId
      },
      message: {
        subject: value.subject,
        message: value.message
      }
    };

    const subject = data.message.subject;
    const userLink = 'https://certiadmin.azurewebsites.net/Users/EditNew/' + data.user.userId;
    let message =
      '<h1>' +
      data.message.subject +
      '</h1>' +
      'Nom: ' +
      data.user.name +
      ' <br/> ' +
      'Email: ' +
      data.user.mail +
      ' <br/> ' +
      'User: ' +
      `<a href="${userLink}">Lien user</a>` +
      ' <br/> <br/> ' +
      data.message.message;

    message += '<br/><br/>';

    this.sendMail(data.user.mail, 'Confirmation ' + subject, message).subscribe();
    this.sendMail(this.certiEmail, '[COPY - NO ACTION REQUIRED] ' + subject, message).subscribe();
    return this.sendMail(this.graphisme, subject, message);
  }

  private sendMail(email, subject, message) {
    this.logger.log(email);
    this.logger.log(subject);
    this.logger.log(message);
    return this.http.post<any>(this.apiURL, {
      to: [email],
      subject: subject,
      body: message
    });
  }

  private sendMails(emails, subject, message) {
    this.logger.log(emails);
    this.logger.log(subject);
    this.logger.log(message);
    return this.http.post<any>(this.apiURL, {
      to: [...emails],
      subject: subject,
      body: message
    });
  }

  sendPaymentInviteMail(email, orderId) {
    return this.http.post<any>(this.apiWithTemplateURL, {
      OrderID: orderId,
      EmailType: 18,
      Recipient: email
    });
  }

  sendComplain(data) {
    return this.http.post<any>(this.apiWithTemplateURL, data);
  }

  sendBrochure(brochure: SendBrochure): Observable<boolean> {
    return this.http.post<boolean>(ENV.API.SEND_BROCHURE, brochure);
  }

  sendLegalShare(email, orderId): Observable<boolean> {
    return this.http.post<any>(this.apiWithTemplateURL, {
      OrderID: orderId,
      EmailType: 19,
      Sender: email,
      Recipient: email
    });
  }

  get websiteTitle(): string {
    return projectID === 'greenfish'
      ? 'Greenfish'
      : projectID === 'hainaut'
        ? 'MDN Hainaut'
        : projectID === 'namur'
          ? 'MDN Namur'
          : projectID === 'liege'
            ? 'MDN Liège'
            : projectID === 'nvn'
              ? 'NVN'
              : 'Certinergie';
  }

  public sendRequestZyva(
    orderZyva: VisitZyva,
    userName: string,
    userEmail: string,
    userId: string,
    ownerName: string,
    ownerAddress: string
  ): Observable<any> {
    const subject = `Demande de visite By Zyva - Certinergie - ${this.websiteTitle}`
    const message = ZyvaHelper.getMailTemplate(
      subject,
      orderZyva,
      userName,
      userEmail,
      userId,
      ownerName,
      ownerAddress
    )
    
    this.sendMail(this.certiEmail, '[COPY - NO ACTION REQUIRED] ' + subject, message).subscribe();
    return this.sendMail(this.zyvaMail, subject, message);
  }
}