import { VisitAdValoris } from './../models/visitAdValoris';
import { PackDB } from './../models/PackDB';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import {
  estates,
  services,
  immoServices,
  regions,
  estateStyles,
  servicesSupp,
  displayedPacks,
  TERRAIN
} from '../data/';
import * as PRODUCTS from '../data/products';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ProductDB } from '../models/productDB';
import { Store } from '@ngrx/store';
import { AppState } from '../../interfaces';

import * as orderSelector from '../store/order/order.selectors';

import { Observable, of, from, Subject, timer, ReplaySubject } from 'rxjs';
import { Region } from '../models/region';
import { map, tap, shareReplay } from 'rxjs/operators';
import { PromoCodeDB } from '../models/PromoCodeDB';
import { ServiceType } from '../models/serviceType';
import { CustomPrice, UserType, Owner, Role } from '../models/user';
import {
  SetCommission,
  SetRegion,
  SetEstateType,
  SetGazChoice,
  SetServices,
  SetNbOfElec,
  SetNbOfGaz,
  SetNumberOfAppartments,
  SetAddress,
  SetOwner,
  SetNbOfIso
} from '../store/order';
import { Products } from '../enums/product.enum';
import { PricingPlans } from '../enums/pricing-plan.enum';
import { getPricesID, projectID, projectIDs, hasAccessAllPack } from '../data/theme-config';
import { packsTheme } from '../data/packs';
import { PreloadOrderInfoDTO } from '../models/PreloadOrderInfoDTO';
import { Address } from '../models/address';
import { LoggerService } from './logger.service';
import { Print } from '../models/print';
import { VENTES } from '../enums/vente.enum';
import { getLanguage } from '../store/ui/ui.selectors';
import { OrdersPaymentState } from 'src/app/client-space/models/ordersPaymentState';
import { OrderStatus } from '../enums/order-status.enum';
import { environment } from 'src/environments/environment';
import { AddressDTO, OrderInfosDTO, EspaceClientOrderDTO } from '../models/OrderDTO';
import { getToken } from '../store/order/order.selectors';

const REFRESH_INTERVAL = 10000;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  dev = false;
  apiUrl = this.dev ? 'http://localhost:52646' : 'https://certiservices.azurewebsites.net';
  apiCoreUrl = this.dev
    ? 'https://localhost:44364/api/v1/espaceclient/'
    : 'https://certicore.azurewebsites.net/api/v1/espaceclient/';
  apiCoreProfile = 'https://certicore.azurewebsites.net/';
  promoCodeURL = 'https://certinergiesystems.azurewebsites.net/api/PackPromo/GetPromocode';
  packsURL = 'https://certinergiesystems.azurewebsites.net/api/PackPromo/Getpack';
  // apiPricesURL = 'https://systems.certinergie.be/api/PackPromo/GetPrices';
  apiPricesURL = 'https://certinergiesystems.azurewebsites.net/api/PackPromo/GetPrices?ordertype=' + getPricesID;
  apiGetOrderInfoURL = 'https://certinergiesystems.azurewebsites.net/api/PackPromo/GetOrder?OrderID=';
  // apiGetMoreOrderInfoURL = 'https://certiservices.azurewebsites.net/api/Order/GetOrderInfo?orderid=';
  apiGetuserOrdersUrl = this.apiUrl + '/api/Order/GetUserOrders?userID=';
  apiGetPreloadOrderURL = 'https://certiservices.azurewebsites.net/api/Order/GetPreloadOrder?OrderID=';
  apiDeleteFileURL = this.apiUrl + '/api/Order/DeleteFile?fileid=';
  apiPostCommentUrl = this.apiUrl + '/api/Order/AddComment';
  userOrdersUrl = environment.CertiCore.userOrdersUrl;
  apiGetMoreOrderInfoURL = this.apiCoreUrl + 'orders/';
  apiProfile = 'api/v1/espaceclient/users/me/accountdetails';
  apiStats = 'api/v1/statistics/consumers/me?language=0';
  apiStatsNl = 'api/v1/statistics/consumers/me?language=1';
  apiComissions = 'api/v1/espaceclient/users/me/commissions';
  apiCreateAdress = 'api/v1/espaceclient/users/me/accountdetails/address';
  apiEditPassword = 'api/v1/users/password';
  apiCreateContact = 'api/v1/espaceclient/users/me/accountdetails/contact';
  apiDeleteAdress = 'api/v1/espaceclient/users/me/accountdetails/address/';
  apiDeleteContact = 'api/v1/espaceclient/users/me/accountdetails/contact/';
  apiEditInfo = 'api/v1/espaceclient/users/me/accountdetails';
  apiEditAdress = 'api/v1/espaceclient/users/me/accountdetails/address/';
  invoiceOrderURL = this.apiCoreUrl + 'orders/{orderid}/invoicelink';
  apiEditContact = 'api/v1/espaceclient/users/me/accountdetails/contact/';
  regionId: number;
  estateId: number;
  reason: number;
  venteType: number;
  products: ProductDB[];
  services: number[];
  customPrices: CustomPrice[];
  packsDB: PackDB[];
  defaultPacksDB: PackDB[];
  promoCodes: PromoCodeDB[];
  userPricingPlan: number;
  userType: number;
  nbOfApp: number;
  userid: string;
  orderid: string;
  language: string;
  orderInfo$: Observable<OrderInfosDTO>;
  publicInfo: OrderInfosDTO;
  ordersInfo$: Observable<EspaceClientOrderDTO[]>;
  token: any;
  userRoles: Role[];
  debouncer: any;
  hasAccessAllPack = hasAccessAllPack;
  projectID = projectID;
  parutions: Print[];
  packsTheme = packsTheme;
  adValoris: VisitAdValoris;
  profile$ = new BehaviorSubject(null);
  stats$ = new BehaviorSubject(null);
  editPassword$ = new BehaviorSubject(null);
  comissions$ = new BehaviorSubject(null);
  private usersSubject: Subject<any>;
  filterOptionsDefault = {
    searchString: '',
    paid: false,
    notPaid: false,
    waitingPayment: false,
    cancelled: false,
    displayCount: 1000,
    orderStatus: OrderStatus.LatestOrders
  };
  displayCountOptions = [20, 40, 100];

  constructor(private http: HttpClient, private store: Store<AppState>, private logger: LoggerService) {
    this.store.select(orderSelector.getRegion).subscribe(r => (this.regionId = r));
    this.store.select(orderSelector.getEstateID).subscribe(e => (this.estateId = e));
    this.store.select(orderSelector.getUserType).subscribe(u => (this.userType = u));
    this.store.select(orderSelector.getReason).subscribe(r => (this.reason = r));
    this.store.select(orderSelector.getVenteType).subscribe(v => (this.venteType = v));
    this.store.select(orderSelector.getCustomPrices).subscribe(cp => (this.customPrices = cp));
    this.store.select(orderSelector.getNbOfAppartments).subscribe(n => (this.nbOfApp = n));
    this.store.select(orderSelector.getPricingPlan).subscribe(pp => (this.userPricingPlan = pp));
    this.store.select(orderSelector.getUserID).subscribe(u => (this.userid = u));
    this.store.select(orderSelector.getServices).subscribe(s => (this.services = s));
    this.store.select(orderSelector.getParutions).subscribe(p => (this.parutions = p));
    this.store.select(orderSelector.getVisitAdValoris).subscribe(av => (this.adValoris = av));
    this.store.select(orderSelector.getUserRoles).subscribe(ur => (this.userRoles = ur));
    this.store.select(getLanguage).subscribe(ln => (this.language = ln));
    this.getPriceFromAPI().subscribe(pr => {
      this.products = pr;
    });

    this.usersSubject = new ReplaySubject(1);
  }

  isGreenfish() {
    return this.projectID === 'greenfish';
  }
  getLangue() {
    this.store.select(getLanguage).subscribe(ln => (this.language = ln));
    return this.language;
  }
  updatePricesOnResolve(pr: ProductDB[]) {
    this.products = pr;
  }

  getOrderInfo(orderid: string): Observable<any> {
    if (!this.isGuid(orderid)) {
      return of(false);
    }
    return this.http.get<any>(environment.CertiCore.checkoutInfo.replace('{orderId}', orderid));
  }

  loadUserOrdersInfo(options: OrdersPaymentState = this.filterOptionsDefault): Observable<EspaceClientOrderDTO[]> {
    this.ordersInfo$ = this.http
      .get<any>(
        this.userOrdersUrl +
          `?SortBy=1&FilterBy=${options.orderStatus}&PageSize=${options.displayCount}&PageNumber=1&SearchBy=${options.searchString}`
        // + `&displayCount=${options.displayCount}&searchString=${options.searchString}&daysOld=null&orderStatus=${options.orderStatus}`
      )
      .pipe(
        tap(this.logger.log),
        shareReplay(1)
      );

    return this.ordersInfo$;
  }

  // This method is responsible for fetching the data.
  // The first one who calls this function will initiate
  // the process of fetching data.
  orderInfoNow(orderid = this.orderid, refresh = false) {
    if (refresh || !this.orderInfo$) {
      // Set up timer that ticks every X milliseconds
      const timer$ = timer(0, REFRESH_INTERVAL);

      // For each timer tick make an http request to fetch new data
      // We use shareReplay(X) to multicast the cache so that all
      // subscribers share one underlying source and don't re-create
      // the source over and over again. We use takeUntil to complete
      // this stream when the user forces an update.
      this.orderInfo$ = this.http.get<any>(environment.CertiCore.orderInfo + orderid).pipe(
        map(data => data),
        tap(d => this.logger.log('httpGet orderInfo', d))
      );
      this.orderInfo$.subscribe(
        result => {
          this.usersSubject.next(result);
        },
        err => this.usersSubject.error(err)
      );
    }
    return this.usersSubject.asObservable();
  }

  publicOrderInfo(orderid = this.orderid) {
    return this.http.get<any>(environment.CertiCore.publicOrderInfo + orderid).pipe(map(data => data));
  }

  loadOrderInfo(orderid: string): Observable<any> {
    if (!this.isGuid(orderid)) {
      return of(false);
    }
    return this.http.get<any>(environment.CertiCore.orderInfo + orderid).pipe(
      map(data => data),
      tap(d => this.logger.log('httpGet orderInfo', d))
    );

    /* this.orderInfo$ = this.refreshOrderData$.pipe(
      tap(this.logger.log),
      switchMap(() =>
        this.http.get<any>(environment.CertiCore.orderInfo + orderid).pipe(
          map(data => data.Data),
          tap(this.logger.log),
          shareReplay(1)
        )
      )
    ); */
  }

  // Public facing API to force the cache to reload the data
  /* forceReload() {
    this.ordersInfo$ = null;
    this.refreshOrderData$.next(undefined);
  } */

  forceReload(orderID: string) {
    this.orderInfoNow(this.orderid, true);
  }

  getPreloadOrderInfo(orderid: string): Observable<any> {
    if (!this.isGuid(orderid)) {
      return of(false);
    }
    return this.http.get<any>(this.apiGetPreloadOrderURL + orderid);
  }

  createContact(body): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiCreateContact;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.post<any>(url, body, httpOptions).pipe(catchError(this.handleError));
  }
  editInfo(body): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiEditInfo;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.patch<any>(url, body, httpOptions).pipe(catchError(this.handleError));
  }
  editAdress(body, id): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiEditAdress + id;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.patch<any>(url, body, httpOptions).pipe(catchError(this.handleError));
  }
  editContact(body, id): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiEditContact + id;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.patch<any>(url, body, httpOptions).pipe(catchError(this.handleError));
  }
  createAdress(body): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiCreateAdress;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.post<any>(url, body, httpOptions).pipe(catchError(this.handleError));
  }
  editPassword(body) {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiEditPassword;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.post<any>(url, body, httpOptions);
  }

  deleteAdress(id): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiDeleteAdress + id;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.delete<any>(url, httpOptions).pipe(catchError(this.handleError));
  }
  deleteContact(id): Observable<any> {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const url = this.apiCoreProfile + this.apiDeleteContact + id;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      }),
      observe: 'response' as const
    };
    return this.http.delete<any>(url, httpOptions).pipe(catchError(this.handleError));
  }
  getComissions(): void {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      })
    };
    this.http
      .get<any>(this.apiCoreProfile + this.apiComissions, httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe(data => {
        this.comissions$.next(data);
      });
  }
  getProfile(): void {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      })
    };
    this.http
      .get<any>(this.apiCoreProfile + this.apiProfile, httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe(data => {
        this.profile$.next(data);
      });
  }
  getStats(): void {
    this.store.select(getToken).subscribe(t => (this.token = t));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token
      })
    };
    let stats = this.language == 'fr' ? this.apiStats : this.apiStatsNl;
    this.http
      .get<any>(this.apiCoreProfile + stats, httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe(data => {
        this.stats$.next(data);
      });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getPriceFromAPI(): Observable<any> {
    return this.http.get<any>(this.apiPricesURL).pipe(map(data => data.Data));
  }

  getPacksFromAPI(): Observable<PackDB[]> {
    let res = from([this.packsTheme]);
    if (
      this.projectID === 'default' ||
      this.projectID === 'namur' ||
      this.projectID === 'hainaut' ||
      this.projectID === 'nvn' ||
      this.projectID === 'liege' ||
      this.projectID === 'greenfish'
    ) {
      if (this.defaultPacksDB) {
        this.logger.log('this.defaultPacksDB', this.defaultPacksDB);
        return of(this.defaultPacksDB);
      }
      res = this.http.get<any>(this.packsURL).pipe(
        map(data => {
          const result =
            displayedPacks.length > 0
              ? data.Data.filter(p => displayedPacks.some(id => id === +p.description))
              : data.Data;

          this.defaultPacksDB = result;

          return result;
        }),
        shareReplay(1)
      );
    }
    return res.pipe(tap());
  }

  getPromoCodesFromAPI(): Observable<PromoCodeDB[]> {
    return this.http.get<any>(this.promoCodeURL).pipe(map(data => data.Data));
  }

  postComment(userID: string, orderID: string, commentTextContent: string) {
    const url = environment.CertiCore.clientComment + orderID;
    return this.http
      .post<any>(url, {
        Comment: commentTextContent
      })
      .subscribe(data => {
        this.logger.log(data);
        this.orderInfoNow(orderID, true);
      });
  }

  /* postComment(userID: string, orderID: string, commentText: string) {
    const url = this.apiPostCommentUrl + `?userID=${userID}&orderID=${orderID}&commentText=${commentText}`;
    return this.http
      .post<any>(url, {
        commentT: commentText
      })
      .subscribe(data => {
        this.logger.log(data);
        this.orderInfoNow(orderID, true);
      });
  } */

  isPromoCodeValid(promoCode: string, productsID: number[], region: number, estate: number): Promise<PromoCodeDB> {
    clearTimeout(this.debouncer);

    return new Promise((resolve, reject) => {
      this.debouncer = setTimeout(() => {
        if (promoCode && promoCode !== '') {
          if (this.promoCodes) {
            const found = this.checkIfCodeFound(this.promoCodes, promoCode, region, productsID, estate);
            resolve(found);
          } else {
            this.getPromoCodesFromAPI().subscribe(data => {
              if (data) {
                this.promoCodes = data;
                const found = this.checkIfCodeFound(data, promoCode, region, productsID, estate);
                resolve(found);
              } else {
                resolve(null);
              }
            });
          }
        } else {
          resolve(null);
        }
      }, 200);
    });
  }

  private checkIfCodeFound(
    data: PromoCodeDB[],
    promoCode: string,
    region: number,
    productsID: number[],
    estate: number
  ) {
    const f = data.filter(
      p =>
        p.KeyWord === promoCode &&
        (p.PromoCode.region === region || !p.PromoCode.region) &&
        (p.PromoCode.estate === estate || !p.PromoCode.estate)
    );
    const found = f.filter(p => p.PromoCode.expertises.every(pr => productsID.indexOf(pr) >= 0))[0];
    return found;
  }

  getProduct(productTypeId: number): ProductDB {
    // hard coded product cause not exist in backend
    if (productTypeId === PRODUCTS.ZYVA) {
      return {
        Price: 495 * 1.21,
        Reduction: 0,
        OrderPriceID: 0,
        OrderType: getPricesID,
        ProductType: PRODUCTS.ZYVA
      } as ProductDB;
    }
    const prices = this.products;
    if (!prices) {
      return new ProductDB();
    }
    const tmp = prices;
    const filteredPrices = [...tmp];

    const regionId = this.regionId;
    const estateTypeId = this.estateId;

    const filteredPrices1 = filteredPrices.find(
      p => p.Region === regionId && p.EstateType === estateTypeId && p.ProductType === productTypeId
    );
    const filteredPrices2 = filteredPrices.find(
      p =>
        (p.Region === regionId || p.Region === null) &&
        (p.EstateType === estateTypeId || p.EstateType === null) &&
        (p.ProductType === productTypeId || p.ProductType === null)
    );

    const dbPrice = !filteredPrices1
      ? !filteredPrices2
        ? <ProductDB>{ Price: 0, Reduction: 0, OrderPriceID: 0, OrderType: 1, ProductType: 27 }
        : filteredPrices2
      : filteredPrices1;

    // this.logger.log(dbPrice);
    return dbPrice;
  }

  getParuPrice(productTypeId: number): { price: number; commission: number } {
    const paru = this.parutions.find(p => p.id === productTypeId) || { price: 0, commission: 0 };

    return { price: paru.price, commission: paru.commission };
  }

  getProductPrice(id: number) {
    if (id === 11111) {
      // peb extra
      return { price: 150, reduc: 0, commission: 0 };
    }
    if (id === PRODUCTS.EXTRA) {
      return { name: 'Frais de dossier Certinergie', price: 25, reduc: 0, commission: 0 };
    }
    if (id === PRODUCTS.URGENT_PRO) {
      return { name: 'Frais urgence NVN', price: 250 * 1.21, reduc: 0, commission: 0 };
    }
    if (id === PRODUCTS.PRINT) {
      let paruPrice = 0;
      this.parutions.filter(p => p.id === PRODUCTS.FullPress).forEach(paru => (paruPrice += paru.price));
      return { price: paruPrice, reduc: 0, commission: 0 };
    }
    if (
      id >= PRODUCTS.PublicationVlan &&
      id <= PRODUCTS.PublicationProximagArdennes &&
      id !== PRODUCTS.VISITADVALORIS &&
      id !== PRODUCTS.LOCKSMITH
    ) {
      return { ...this.getParuPrice(id), reduc: 0 };
    }
    if (id === PRODUCTS.PublicationNotaireBE && !!this.products) {
      const ipl =
        this.products.find(
          p =>
            p.ProductType === PRODUCTS.PublicationNotaireBE &&
            p.SaleType === (this.venteType === VENTES.VENTE_PUB ? 3 : this.venteType)
        ) || this.products.find(p => p.ProductType === PRODUCTS.PublicationNotaireBE);
      return ipl
        ? { price: ipl.Price, reduc: ipl.Reduction, commission: ipl.Commission }
        : { price: 0, reduc: 0, commission: 0 };
    }
    let prod = this.getProduct(id);
    prod.Reduction = this.getStatutReduc(prod.ProductType, prod.Reduction, prod, this.userType > UserType.Particulier);

    const newDbPrice = this.checkCustomPrice(id, prod);
    prod = newDbPrice ? newDbPrice : prod;

    /* if (id === PRODUCTS.PEB && this.estateId === estateTypes.IMMEUBLE_APPART && this.nbOfApp > 0) {
      const newPrice = prod.Price + 100 * (this.nbOfApp - 1);
      return { price: newPrice, reduc: prod.Reduction };
    } */

    return { price: prod.Price, reduc: prod.Reduction, commission: prod.Commission };
  }

  checkCustomPrice(productTypeId, dbPrice) {
    let newDbPrice = null;

    const customP = this.customPrices
      ? this.customPrices.find(
          cp =>
            (cp.region === this.regionId || cp.region == null) &&
            (cp.estateType === this.estateId || cp.estateType == null) &&
            cp.productTypeId === productTypeId
        )
      : null;

    if (customP) {
      newDbPrice = { ...dbPrice };
      newDbPrice.Price = customP.price;
      newDbPrice.Reduction = 0;

      if (customP.commission > 0) {
        newDbPrice.Price = customP.price + customP.commission;
        newDbPrice.Reduction = customP.commission;
        this.store.dispatch(new SetCommission(customP.commission));
      }
    }

    return newDbPrice;
  }

  getEstateTypes() {
    return estates;
  }

  getEstateStyles() {
    return estateStyles;
  }

  getServiceTypes(): ServiceType[] {
    return [...services, ...servicesSupp];
  }

  getImmoServiceTypes(): ServiceType[] {
    return immoServices;
  }

  getImmoPacks() {
    return this.getPacksFromAPI().pipe(
      map(p => {
        if (this.regionId === -1 || this.estateId === -1) {
          return null;
        }
        let packsDB;

        if (
          projectID === 'namur' ||
          projectID === 'hainaut' ||
          projectID === 'nvn' ||
          projectID === 'liege' ||
          projectID === 'greenfish'
        ) {
          packsDB = p.filter(pa => pa.userRoles.some(ur => ur === projectIDs.packRoleID));
          this.logger.log('Role pack,', this.packsDB);
        } else {
          packsDB = p;
        }

        let p1: PackDB[] = [];
        let p2: PackDB[] = [];
        let p3: PackDB[] = [];
        let p4: PackDB[] = [];

        const tmp = packsDB
          .filter(pa => pa.description !== null)
          .sort((a, b) => parseInt(a.description, 10) - parseInt(b.description, 10));
        p1 = tmp.filter(pa => pa.region === this.regionId && pa.estate === this.estateId);
        p2 = tmp.filter(pa => pa.region === this.regionId && (pa.estate === -1 || pa.estate === null));
        p3 = tmp.filter(pa => pa.region === null && pa.estate === this.estateId);
        p4 = tmp.filter(pa => pa.region === null && pa.estate === null);

        let packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];
        this.packsDB = [...packs];

        if (projectID === 'nvn' && this.estateId === TERRAIN) {
          packs = packs.filter(pa => pa.description !== '9994');
        }
        return packs;
      })
    );
  }

  getPacksToDisplay() {
    if (this.regionId === -1 || this.estateId === -1) {
      return null;
    }
    const filteredPacks = [...this.packsDB].filter(p => p.description !== null);
    const p1 = filteredPacks.filter(p => p.region === this.regionId && p.estate === this.estateId);
    const p2 = filteredPacks.filter(p => p.region === this.regionId && p.estate === -1);
    const p3 = filteredPacks.filter(p => p.region === null && p.estate === this.estateId);
    const p4 = filteredPacks.filter(p => p.region === null && p.estate === null);

    const packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];

    return packs;
  }

  getPackPrice(packID: number): PackDB {
    if (this.regionId === -1 || this.estateId === -1) {
      return null;
    }
    const p1 = this.packsDB.filter(p => p.region === this.regionId && p.estate === this.estateId);
    const p2 = this.packsDB.filter(p => p.region === this.regionId && p.estate === -1);
    const p3 = this.packsDB.filter(p => p.region === null && p.estate === this.estateId);
    const p4 = this.packsDB.filter(p => p.region === null && p.estate === null);

    const packs: PackDB[] = [...p1, ...p2, ...p3, ...p4];

    const pack = packs.find(p => parseInt(p.id, 10) === packID);

    return pack;
  }

  getProductsForPack(id: string) {
    return this.getImmoPacks().pipe(
      map(packs => {
        if (!packs) {
          return [];
        }
        const pack = packs.filter(p => p.description === id);
        return pack[0] && pack[0].services ? pack[0].services : [];
      })
    );
  }

  getProductsAllIn() {
    return [
      PRODUCTS.PEB,
      PRODUCTS.ELEC,
      PRODUCTS.PHOTO,
      PRODUCTS.VISITE360,
      PRODUCTS.PLAN,
      PRODUCTS.PublicationNotaireBE,
      PRODUCTS.PublicationImmowebXL,
      PRODUCTS.ImmovlanOnline,
      PRODUCTS.NotaireBEOnline
    ];
  }

  getProductsPresquIn() {
    return [
      PRODUCTS.PEB,
      PRODUCTS.ELEC,
      PRODUCTS.PHOTO,
      PRODUCTS.VISITE360,
      PRODUCTS.PLAN,
      PRODUCTS.PublicationNotaireBE,
      PRODUCTS.PublicationImmowebXL,
      PRODUCTS.ImmovlanOnline,
      PRODUCTS.NotaireBEOnline
    ];
  }

  getRegionTypes(): Region[] {
    return regions;
  }

  getRegionByID(id: number) {
    return this.getRegionTypes().find(r => r.id === id);
  }

  getStatutReduc(id: number, reduc: number, prod: ProductDB, pro: boolean): number {
    const plandID = this.userPricingPlan;
    var reduction = reduc;
    if (id < 51 || id >= 100) {
      switch (plandID) {
        case PricingPlans.BRONZE:
          reduction = prod.ReductionBronze;
          break;
        case PricingPlans.SILVER:
          reduction = prod.ReductionSilver;
          break;
        case PricingPlans.GOLD:
          reduction = prod.ReductionGold;
          break;
        case PricingPlans.PLATINUM:
          reduction = prod.ReductionPlatinum;
          break;
        case PricingPlans.BRONZE_FLANDRE:
          reduction = prod.ReductionBronze;
          break;
        case PricingPlans.SILVER_FLANDRE:
          reduction = prod.ReductionSilver;
          break;
        case PricingPlans.GOLD_FLANDRE:
          reduction = prod.ReductionGold;
          break;
        case PricingPlans.PLATINUM:
          reduction = prod.ReductionPlatinum;
          break;
        default:
          if (pro && prod.ReductionPro) {
            reduction = prod.ReductionPro;
          } else {
            reduction = reduc;
          }
          break;
      }
    } else if (id === Products.PEB_ELEC && pro) {
      reduction = 25;
    } else if ((id === Products.TRIO_A || id === Products.TRIO_E) && pro) {
      reduction = 35;
    }

    return reduction;
  }

  public deleteOrderFile(fileid: string): Observable<any> {
    if (!this.isGuid(fileid)) {
      return of(false);
    }
    this.logger.log(this.apiDeleteFileURL, fileid);
    return this.http.get<any>(this.apiDeleteFileURL + fileid);
  }

  public preloadOrder(id: string) {
    this.getPreloadOrderInfo(id)
      .pipe(map(d => d.Data))
      .subscribe((o: PreloadOrderInfoDTO) => {
        this.logger.log('Preload order info ', [{ order: o }, { orderid: id }]);

        if (!isNaN(o.Region)) {
          this.store.dispatch(new SetRegion(o.Region));

          if (!isNaN(o.EstateType)) {
            this.store.dispatch(new SetEstateType(o.EstateType));
            if (o.OrderProducts && o.OrderProducts.length > 0 && o.OrderProducts[0]) {
              this.store.dispatch(new SetServices([]));
              const op = o.OrderProducts.map(d => d.ProductTypeID);

              const nbElecSupp = op.filter(p => p === PRODUCTS.ELEC_SUPP).length;
              const nbGazSupp = op.filter(p => p === PRODUCTS.GAZ_SUPP).length;
              let nbApp = nbElecSupp > nbGazSupp ? nbElecSupp : nbGazSupp;
              nbApp = +nbApp + 1;

              const hasGazInUrl = op.some(p => +p === PRODUCTS.GAZ_CONTROLE);
              if (hasGazInUrl) {
                this.store.dispatch(new SetGazChoice(0));
              }
              op.forEach(element => {
                const prod = +element;
                if (!isNaN(prod)) {
                  this.setService(prod);
                }
              });

              if (o.EstateType === 2) {
                if (nbApp > 0) {
                  this.store.dispatch(new SetNumberOfAppartments(nbApp));
                  if (nbElecSupp > 0) {
                    this.setElecSupp(nbApp);
                  }
                  if (nbGazSupp > 0) {
                    this.setGazSupp(nbApp);
                  }
                }
              }
            }
          }

          if (o.AddressVisit.Street && o.AddressVisit.Street !== '') {
            const add = new Address();
            add.street = o.AddressVisit.Street;
            add.number = o.AddressVisit.Number;
            add.zip = o.AddressVisit.Zip + '';
            add.city = o.AddressVisit.City;
            add.boxNumber = o.AddressVisit.BoxNumber;
            add.apartmentNumber = o.AddressVisit.ApartmentNumber;
            this.store.dispatch(new SetAddress(add));
          }

          if (o.Owner.LastName && o.Owner.LastName !== '') {
            const owner = new Owner();
            owner.firstname = o.Owner.FirstName;
            owner.lastname = o.Owner.LastName;
            owner.email = o.Owner.Email;
            owner.phone = o.Owner.Phone;
            this.store.dispatch(new SetOwner(owner));
          }
        }
      });
  }

  removeService(id) {
    this.services = [...this.services].filter(pr => pr !== id);
    this.store.dispatch(new SetServices(this.services));
  }

  setService(id) {
    if (id === PRODUCTS.GAZ_SUPP || id === PRODUCTS.ELEC_SUPP) {
      return;
    }
    this.services = [...this.services];
    const index = this.services.indexOf(id);

    const indexCiterneE = this.services.indexOf(PRODUCTS.CITERNE_ENTERREE);
    const indexCiterneA = this.services.indexOf(PRODUCTS.CITERNE_AERIENNE);

    const indexPlan2D = this.services.indexOf(PRODUCTS.PLAN);
    const indexPlan3D = this.services.indexOf(PRODUCTS.PLAN3D);
    const indexVisite360 = this.services.indexOf(PRODUCTS.VISITE360);

    const indexSMS = this.services.indexOf(PRODUCTS.SMS);

    const indexPanel =
      this.services.indexOf(PRODUCTS.PANEL) > -1 ||
      this.services.indexOf(PRODUCTS.PANEL_SUPPORT) > -1 ||
      this.services.indexOf(PRODUCTS.PANELBIDDIT) > -1 ||
      this.services.indexOf(PRODUCTS.PANELBIDDIT_SUPPORT) > -1;

    if (indexCiterneE > -1 && id === PRODUCTS.CITERNE_AERIENNE) {
      this.services.splice(indexCiterneE, 1);
    } else if (indexCiterneA > -1 && id === PRODUCTS.CITERNE_ENTERREE) {
      this.services.splice(indexCiterneA, 1);
    }
    if (indexSMS > -1 && (id === PRODUCTS.PANEL || id === PRODUCTS.PANEL_SUPPORT)) {
      this.services.splice(indexSMS, 1);
    }

    if (id === 1) {
      // this.services = this.services.filter(pr => pr !== 14);
    }

    if (
      id === PRODUCTS.PANEL ||
      id === PRODUCTS.PANEL_SUPPORT ||
      id === PRODUCTS.PANELBIDDIT ||
      id === PRODUCTS.PANELBIDDIT_SUPPORT
    ) {
      this.services = this.services.filter(
        pr =>
          pr !== PRODUCTS.SMS &&
          pr !== PRODUCTS.PANEL &&
          pr !== PRODUCTS.PANEL_SUPPORT &&
          pr !== PRODUCTS.PANELBIDDIT &&
          pr !== PRODUCTS.PANELBIDDIT_SUPPORT
      );
    }

    if (id === PRODUCTS.PEB) {
      this.services = this.services.filter(pr => pr !== PRODUCTS.PEB_SUPP);
      this.services = this.services.filter(pr => pr !== PRODUCTS.PEB_Partiel);
    }

    if (id === PRODUCTS.ELEC) {
      this.services = this.services.filter(pr => pr !== PRODUCTS.ELEC_SUPP);
    }

    if (id === PRODUCTS.GAZ_CONTROLE || id === PRODUCTS.GAZ_CONTROLE_PERIO) {
      this.services = this.services.filter(pr => pr !== PRODUCTS.GAZ_SUPP && pr !== PRODUCTS.ISO);
    }

    if (indexPlan2D < 0 && id === PRODUCTS.PLAN3D) {
      this.setService(PRODUCTS.PLAN);
    }

    if (!indexPanel && (id === PRODUCTS.PANEL || id === PRODUCTS.PANEL_SUPPORT)) {
      this.setService(PRODUCTS.SMS);
    }

    if (indexPlan2D < 0 && id === PRODUCTS.VISITE360) {
      this.setService(PRODUCTS.PLAN);
    }

    if (indexPlan2D > -1 && indexPlan3D > -1 && id === PRODUCTS.PLAN) {
      this.services = this.services.filter(pr => pr !== PRODUCTS.PLAN3D);
    }

    if (indexPlan2D > -1 && indexVisite360 > -1 && id === PRODUCTS.PLAN) {
      this.services = this.services.filter(pr => pr !== PRODUCTS.VISITE360);
    }

    if (index > -1) {
      // this.services.splice(index, 1);
      this.services = this.services.filter(pr => pr !== id);
    } else {
      this.services = [...this.services, id];

      if (id === PRODUCTS.GAZ_CONTROLE) {
        this.setGazSupp(this.nbOfApp);
      }
      if (id === PRODUCTS.ELEC) {
        this.setElecSupp(this.nbOfApp);
      }
      if (id === PRODUCTS.GAZ_CONTROLE || id === PRODUCTS.GAZ_CONTROLE_PERIO) {
        this.setPlanIso(1);
      }
    }
    this.store.dispatch(new SetServices(this.services));
  }

  setPlanIso(nb: number) {
    this.services = this.services.filter(pr => pr !== PRODUCTS.ISO);

    if (nb > 0) {
      const isoArray = [...Array(nb).fill(PRODUCTS.ISO)];
      this.services = this.services.concat(isoArray);
    }

    this.store.dispatch(new SetServices(this.services));
    this.store.dispatch(new SetNbOfIso(nb));
  }

  setPebSupp(nb) {
    this.services = this.services.filter(pr => pr !== PRODUCTS.PEB_SUPP);

    if (nb > 1) {
      const pebSuppArray = [...Array(nb - 1).fill(PRODUCTS.PEB_SUPP)];
      this.services = this.services.concat(pebSuppArray);
    }

    this.store.dispatch(new SetServices(this.services));
  }

  setAuditSupp(nb: number) {
    this.services = this.services.filter(pr => pr !== PRODUCTS.AUDIT);

    const auditSuppArray = [...Array(nb).fill(PRODUCTS.AUDIT)];
    this.services = this.services.concat(auditSuppArray);

    this.store.dispatch(new SetServices(this.services));
  }

  setElecSupp(nb) {
    this.services = this.services.filter(pr => pr !== PRODUCTS.ELEC_SUPP);

    if (nb > 1) {
      const elecSuppArray = [...Array(nb - 1).fill(PRODUCTS.ELEC_SUPP)];
      this.services = this.services.concat(elecSuppArray);
    }

    this.store.dispatch(new SetServices(this.services));
    this.store.dispatch(new SetNbOfElec(nb));
  }

  setGazSupp(nb) {
    this.services = this.services.filter(pr => pr !== PRODUCTS.GAZ_SUPP);

    if (nb > 1) {
      const gazSuppArray = [...Array(nb - 1).fill(PRODUCTS.GAZ_SUPP)];
      this.services = this.services.concat(gazSuppArray);
    }

    this.store.dispatch(new SetServices(this.services));
    this.store.dispatch(new SetNbOfGaz(nb));
  }

  formatAddress(address: AddressDTO) {
    return address.Number && address.Street
      ? `${address.Street} ${address.Number} ${address.Zip} ${address.City}`
      : address.Street
      ? `${address.Street} ${address.Zip} ${address.City}`
      : `${address.Zip} ${address.City}`;
  }

  getInvoiceLink(orderId: string): Observable<{ Link: string }> {
    return this.http.get<{ Link: string }>(this.invoiceOrderURL.replace('{orderid}', orderId));
  }

  public isGuid(stringToTest) {
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  checkTva(vatNumber: string): Observable<boolean> {
    vatNumber = vatNumber.replace(/\D/g, '');
    return this.http.get<boolean>(`${environment.CertiCore.checkVat}?countryCode=BE&vatNumber=${vatNumber}`);
  }
}
