import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { getOrder, getUserID } from 'src/app/core/store/order/order.selectors';
import * as PRODUCTS from 'src/app/core/data/products';
import { VisitZyva } from 'src/app/core/models/visitZyva';
import { finalize, take } from 'rxjs/operators';
import { IOrder } from 'src/app/core/store/order';
import { SendEmailService } from 'src/app/core/services/send-email.service';


const URL = 'https://certiservices.azurewebsites.net/api/Order/UploadPhotoNotary';

@Component({
  selector: 'app-visit-zyva-request-dialog',
  templateUrl: './visit-zyva-request-dialog.component.html',
  styleUrls: ['./visit-zyva-request-dialog.component.scss']
})
export class VisitZyvaRequestDialogComponent implements OnInit {
  zyvaFormGroup: FormGroup;
  sent: boolean;
  uploadLinks = [];
  userID: string;
  order: IOrder;

  visitZyva: VisitZyva = {
    forfait: {
      id: PRODUCTS.ZYVA,
      description: 'Visite ZYVA',
      active: false,
      devis: false,
      price: 495 * 1.21
    },
    option: {
      id: 0,
      description: 'disponibilité clé',
      active: false,
      devis: false,
      price: 0
    },
    instructions: '',
    uploadLinks: []
  };

  public uploader: FileUploader = new FileUploader({
    url: URL,
    removeAfterUpload: false,
    autoUpload: true
  });

  constructor(
    private _formBuilder: FormBuilder,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private mailService: SendEmailService
  ) { }

  ngOnInit() {
    this.zyvaFormGroup = this._formBuilder.group({
      ownerName: [''],
      address: [''],
      instructions: [''],
      hasKey: ['true']
    });
    this.sent = false;
    this.store.select(getUserID).subscribe(us => (this.userID = us));
    this.store.select(getOrder).subscribe(o => (this.order = o));


    this.uploader.onBuildItemForm = (item, form) => {
      item.withCredentials = false;
      const id = this.userID && this.userID !== '' ? this.userID : 'bc132cfc-aeb1-4fb0-b8ba-0193f96b8230';
      form.append('userID', id);
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.uploadLinks = [...this.uploadLinks, JSON.parse(response)];
    };
  }

  private initForm(vz: VisitZyva): void {
    this.uploadLinks = vz.uploadLinks ?? [];
    this.zyvaFormGroup.get('instructions').setValue(vz.instructions);
    this.zyvaFormGroup.get('hasKey').setValue(vz.option.active ? 'true' : 'false');
  }

  close() {
    this.data.dialog.closeAll();
  }

  fileIndex(i: number) {
    return i + 1;
  }

  deletePhotoLink(index: number) {
    this.uploadLinks = [...this.uploadLinks];
    this.uploadLinks.splice(index, 1);
  }

  AddVisit() {
    const visit = { ...this.visitZyva };
    visit.forfait.active = true;
    visit.instructions = this.zyvaFormGroup.value.instructions;
    visit.option.active = this.zyvaFormGroup.value.hasKey === 'true';
    visit.uploadLinks = this.uploadLinks;
    this.sendZyvaMailFromModal(visit);
    
  }

  private sendZyvaMailFromModal(visit: VisitZyva): void {
    const userName = `${this.order.user.firstname ? this.order.user.firstname + ' ' : ''}${this.order.user.lastname ?? ''}`
    const ownerName =  this.zyvaFormGroup.get('ownerName')?.value;
    const address = this.zyvaFormGroup.get('address')?.value;
    this.mailService.sendRequestZyva(visit, userName, this.order.user.email, this.order.user.userid, ownerName, address).pipe(take(1), finalize(() => this.close())).subscribe()
  }

  cancelVisit() {
    this.close();
  }
}
