import { ActionTypes, Actions } from './order.actions';

import * as estates from '../../data/estates';
import { Product } from '../../models/product';

import * as PRODUCTS from '../../data/products';
import { Reduction } from '../../models/reduction';
import { Document } from '../../models/document';
import { Contact, Owner, UserForm, UserType, User, Role, UserRole } from '../../models/user';
import { Address } from '../../models/address';
import { Region } from '../../models/region';

import { defaultRegion, projectID } from '../../data/theme-config';
import { Print } from '../../models/print';
import { Visit } from '../../models/visit';
import { VisitContact } from '../../models/visitContact';
import { VenteInfo, defaultVenteInfo } from '../../models/venteInfo';
import { VisitAdValoris } from '../../models/visitAdValoris';
import { Panel } from '../../models/paper';

import { forceInvoiceToPro } from '../../data/theme-config';
import { stat } from 'fs';

export interface IOrder {
  id: string;
  regionType: number;
  region: Region;
  estateType: number;
  estateStyle: number;
  estateName: string;
  nbOfAppartments: number;
  services: number[];
  immoServices: number[];
  products: Product[];
  productsForAgency: Product[];
  hasPeb: boolean;
  hasAudit: boolean;
  hasPebPartiel: boolean;
  hasElec: boolean;
  hasCiterne: boolean;
  hasPlan: boolean;
  hasGaz: boolean;
  pebNb: string;
  nbOfElec: number;
  nbOfGaz: number;
  gazChoice: number;
  paymentType: string;
  acteType: string;
  sendByMail: boolean;
  remarks: string;
  invoiceTo: string;
  immoPack: number;
  immoPackIds: number[];
  reductions: Reduction[];
  reduction: number;
  reductionWithoutComm: number;
  price: number;
  onlinePayment: boolean;
  promoCode: string;
  urgence: boolean;
  urgencePro: boolean;
  address: Address;
  owner: Owner;
  contact: Contact;
  contacts: Contact[];
  dateButoir: Date;
  document: Document;
  goGetKeys: boolean;
  commission: number;
  contactChoice: string;
  nn: string;
  nbOfIso: number;
  promoCodeID: string;
  packID: string;
  packName: string;
  reason: number;
  clientType: number;
  immoOrCtrl: number;
  venteType: number;
  venteInfo: VenteInfo;
  parutions: Print[];
  panels: Panel[];
  panelsInfo: string;
  parutionFileLinks: string[];
  parutionsComment: string;
  visits: Visit[];
  visitContact: VisitContact;
  visitAdValoris: VisitAdValoris;
  splitInvoice: boolean;
  askForExpertise: boolean;
  askForOffer: boolean;
  addressKeyID: string;
  addressKeyPrice: number;

  newUser: UserForm;
  user: User;
  token: string;
}

export const initialOrderState: IOrder = {
  id: '',
  regionType: defaultRegion,
  region:
    defaultRegion === 0
      ? { id: 0, name: 'Région wallonne', fileName: 'be-wal' }
      : defaultRegion === 1
      ? { id: 1, name: 'Région bruxelloise', fileName: 'be-bxl' }
      : defaultRegion === 2
      ? { id: 2, name: 'Région flamande', fileName: 'be-vl' }
      : new Region(),
  estateType: -1,
  estateStyle: -1,
  estateName: '',
  nbOfAppartments: -1,
  services: [],
  immoServices: [],
  products: [],
  productsForAgency: [],
  hasPeb: false,
  hasAudit: false,
  hasPebPartiel: false,
  hasElec: false,
  hasCiterne: false,
  hasPlan: false,
  hasGaz: false,
  pebNb: '',
  nbOfElec: 0,
  nbOfGaz: 0,
  gazChoice: 1,
  paymentType: 'classic',
  acteType: 'ILLPAY',
  sendByMail: false,
  remarks: '',
  invoiceTo: forceInvoiceToPro ? 'toPro' : 'toProprio',
  immoPack: -1,
  immoPackIds: [],
  reductions: [],
  reduction: 0,
  reductionWithoutComm: 0,
  price: 0,
  onlinePayment: false,
  promoCode: '',
  urgence: false,
  urgencePro: false,
  address: new Address(),
  owner: new Owner(),
  contact: new Contact(),
  contacts: [],
  dateButoir: null,
  document: new Document(),
  goGetKeys: false,
  commission: 0,
  contactChoice: 'sameAsOwner',
  nn: '',
  nbOfIso: 0,
  promoCodeID: '',
  packID: '',
  packName: '',
  reason: -1,
  clientType: UserType.Particulier,
  immoOrCtrl: -1,
  venteType: -1,
  venteInfo: defaultVenteInfo,
  parutions: [],
  panels: [],
  panelsInfo: '',
  parutionFileLinks: [],
  parutionsComment: '',
  visits: [],
  visitContact: new VisitContact(),
  visitAdValoris: {
    forfaits: [
      {
        id: -1,
        description: '',
        active: false,
        devis: false,
        price: 0
      }
    ],
    options: [
      {
        id: -1,
        description: '',
        active: false,
        devis: false,
        price: 0
      }
    ],
    instructions: ''
  },
  splitInvoice: false,
  askForExpertise: false,
  askForOffer: false,
  addressKeyID: '',
  addressKeyPrice: 0,

  newUser: new UserForm(),
  user: {
    userid: '',
    title: '',
    firstname: '',
    lastname: '',
    email: '',
    commercialname: '',
    companyname: '',
    phone: '',
    gsm: '',
    indicator: '',
    indicatorphone: '',
    address: new Address(),
    sendByMail: false,
    hasImmoAccess: false,
    userType: UserType.Particulier,
    hasCommission: false,
    pricingPlan: 0,
    roles: [],
    tva: '',
    customPrices: [],
    notifications: [],
    pendingOrders: 0,
    unpaidAmount: 0,
    unpaidCount: 0,
    unpaidCallbackAmount: 0,
    unpaidCallbackCount: 0,
    yearlyOrdersCount: 0,
    ongoingOrders: 0,
    unsignedBdc: 0,
    newOrdersCount: 0,
    ordersCount: 0,
    latestOrdersCount: 0,
    canceledOrdersCount: 0,
    suspensOrdersCount: 0,
    orderId: '',
    consumerAddresses: []
  },
  token: ''
};

export function reducer(state: IOrder = initialOrderState, action: Actions): IOrder {
  switch (action.type) {
    case ActionTypes.SET_REGION:
      return { ...state, regionType: action.payload as number };

    case ActionTypes.SET_REGIONINFO:
      return { ...state, region: action.payload as Region };

    case ActionTypes.SET_ESTATETYPE:
      return {
        ...state,
        estateType: action.payload as number,
        estateName: getEstateName(action.payload as number),
        nbOfAppartments: action.payload === estates.IMMEUBLE_APPART ? 1 : -1,
        estateStyle: getEstateStyle(action.payload)
      };

    case ActionTypes.SET_ESTATESTYLE:
      return {
        ...state,
        estateStyle: action.payload as number,
        estateType: action.payload === 3 ? estates.TERRAIN : -1,
        nbOfAppartments: -1,
        venteType:
          projectID === 'namur' || projectID === 'hainaut' || projectID === 'liege' || projectID === 'nvn'
            ? -1
            : state.venteType
      };

    case ActionTypes.SET_NUMBEROFAPPARTMENTS:
      return {
        ...state,
        nbOfAppartments: action.payload as number,
        estateType: estates.BUILDING,
        estateStyle: estates.BUILDING,
        estateName: 'IMMEUBLE_APPART'
      };

    case ActionTypes.SET_SERVICES:
      return {
        ...state,
        services: [...(action.payload as number[])],
        parutions: action.payload.length > 0 ? state.parutions : [],
        visits: action.payload.length > 0 ? state.visits : []
      };

    case ActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: [...(action.payload as Product[])],
        hasPeb: (action.payload as Product[]).some(p => p.id === PRODUCTS.PEB),
        hasAudit: (action.payload as Product[]).some(p => p.id === PRODUCTS.AUDIT),
        hasPebPartiel: (action.payload as Product[]).some(p => p.id === PRODUCTS.PEB_Partiel),
        hasElec: (action.payload as Product[]).some(p => p.id === PRODUCTS.ELEC),
        hasCiterne: (action.payload as Product[]).some(
          p => p.id === PRODUCTS.CITERNE_AERIENNE || p.id === PRODUCTS.CITERNE_ENTERREE
        ),
        hasPlan: (action.payload as Product[]).some(p => p.id === PRODUCTS.PLAN),
        hasGaz: (action.payload as Product[]).some(
          p => p.id === PRODUCTS.GAZ_CONTROLE || p.id === PRODUCTS.GAZ_CONTROLE_PERIO
        ),
        nbOfElec: (action.payload as Product[]).some(p => p.id === PRODUCTS.ELEC)
          ? state.nbOfElec > 0
            ? state.nbOfElec
            : 1
          : 0,
        nbOfGaz: (action.payload as Product[]).some(
          p => p.id === PRODUCTS.GAZ_CONTROLE || p.id === PRODUCTS.GAZ_CONTROLE_PERIO
        )
          ? state.nbOfGaz > 0
            ? state.nbOfGaz
            : 1
          : 0,
        price: checkPriceWithoutReduction(action.payload as Product[])
      };

    case ActionTypes.SET_NBOFELEC:
      return {
        ...state,
        nbOfElec: action.payload as number
      };

    case ActionTypes.SET_NBOFGAZ:
      return {
        ...state,
        nbOfGaz: action.payload as number
      };

    case ActionTypes.SET_GAZCHOICE:
      return {
        ...state,
        gazChoice: action.payload as number
      };

    case ActionTypes.SET_PAYMENTTYPE:
      return {
        ...state,
        paymentType: action.payload as string,
        onlinePayment: action.payload === 'online' ? true : state.onlinePayment
      };

    case ActionTypes.SET_ACTETYPE:
      return {
        ...state,
        acteType: action.payload as string
      };

    case ActionTypes.TOGGLE_SENDBYMAIL:
      return {
        ...state,
        sendByMail: !state.sendByMail as boolean
      };

    case ActionTypes.SET_REMARKS:
      return {
        ...state,
        remarks: action.payload as string
      };

    case ActionTypes.SET_INVOICETO:
      return {
        ...state,
        invoiceTo: action.payload as string
      };

    case ActionTypes.SET_IMMOPACK:
      return {
        ...state,
        immoPack: action.payload as number
      };

    case ActionTypes.SET_REDUCTIONS:
      const redWithoutComm = checkReduction(action.payload);
      const maxComm = checkMaxCommission(checkReduction(action.payload) as number, state.commission) as number;
      const red = (redWithoutComm - maxComm) as number;
      return {
        ...state,
        reductions: action.payload as Reduction[],
        reduction: red,
        reductionWithoutComm: redWithoutComm,
        commission: maxComm
      };

    case ActionTypes.SET_COMMISSION:
      return {
        ...state,
        commission: action.payload as number,
        reduction: state.reductionWithoutComm - action.payload
      };

    case ActionTypes.TOGGLE_ONLINEPAYMENT:
      return {
        ...state,
        onlinePayment: !state.onlinePayment as boolean
      };

    case ActionTypes.SET_PROMOCODE:
      return {
        ...state,
        promoCode: action.payload as string
      };

    case ActionTypes.SET_PEBNB:
      return {
        ...state,
        pebNb: action.payload as string
      };

    case ActionTypes.TOGGLE_URGENCE:
      return {
        ...state,
        urgence: !state.urgence as boolean
      };

    case ActionTypes.TOGGLE_URGENCE_PRO:
      return {
        ...state,
        urgencePro: action.payload as boolean
      };

    case ActionTypes.SET_ADDRESS:
      return {
        ...state,
        address: { ...state.address, ...(action.payload as Address) }
      };

    case ActionTypes.SET_ZIPCODE:
      return {
        ...state,
        address: { ...state.address, zip: action.payload as string }
      };

    case ActionTypes.SET_OWNER:
      return {
        ...state,
        owner: action.payload as Owner
      };

    case ActionTypes.SET_USERFORM:
      return {
        ...state,
        newUser: action.payload as User,
        user: { ...state.user, ...(action.payload as User) }
      };

    case ActionTypes.SET_DATEBUTOIR:
      return {
        ...state,
        dateButoir: action.payload as Date
      };

    case ActionTypes.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload as Document
      };

    case ActionTypes.RESET_ORDERSTATE:
      return {
        ...initialOrderState,
        services: [],
        reductions: [],
        products: [],
        immoServices: []
      };

    case ActionTypes.SET_NEWUSERTYPE:
      return {
        ...state,
        user: { ...state.user, userType: action.payload as UserType }
      };

    case ActionTypes.SET_CONTACTCHOICE:
      return {
        ...state,
        contactChoice: action.payload as string
      };

    case ActionTypes.SET_CONTACT:
      return {
        ...state,
        contact: action.payload as Contact
      };

    case ActionTypes.SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload as Contact[]
      };

    case ActionTypes.COPY_CONTACT:
      return {
        ...state,
        contact:
          state.owner.firstname || state.owner.lastname || state.owner.phone || state.owner.gsm
            ? ({
                firstname: state.owner.firstname,
                lastname: state.owner.lastname,
                phone: state.owner.phone,
                gsm: state.owner.gsm
              } as Contact)
            : ({
                firstname: state.user.firstname,
                lastname: state.user.lastname,
                phone: state.user.phone,
                gsm: state.user.gsm
              } as Contact)
      };

    case ActionTypes.SET_GOGETKEYS:
      return {
        ...state,
        goGetKeys: action.payload as boolean
      };

    case ActionTypes.SET_GUID:
      return { ...state, user: { ...state.user, userid: action.payload as string } };

    case ActionTypes.SET_USERINFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload as User),
          hasImmoAccess: checkHasImmoAccess((action.payload as User).roles)
        }
      };

    case ActionTypes.RESET_USERINFO:
      return {
        ...state,
        user: { ...initialOrderState.user }
      };

    case ActionTypes.SET_NN:
      return {
        ...state,
        nn: action.payload as string
      };

    case ActionTypes.SET_PROMOCODEID:
      return {
        ...state,
        promoCodeID: action.payload as string
      };

    case ActionTypes.SET_PACKID:
      return {
        ...state,
        packID: action.payload as string
      };

    case ActionTypes.SET_PACKNAME:
      return {
        ...state,
        packName: action.payload as string
      };

    case ActionTypes.SET_NBOFISO:
      return {
        ...state,
        nbOfIso: action.payload as number
      };

    case ActionTypes.SET_REASON:
      return {
        ...state,
        reason: action.payload as number
      };

    case ActionTypes.SET_CLIENTTYPE:
      return {
        ...state,
        clientType: action.payload as number
      };

    case ActionTypes.SET_IMMOORCTRL:
      return {
        ...state,
        reason: -1,
        immoOrCtrl: action.payload as number
      };

    case ActionTypes.SET_VENTETYPE:
      return {
        ...state,
        venteType: action.payload as number
      };

    case ActionTypes.SET_VENTEINFO:
      return {
        ...state,
        venteInfo: action.payload as any
      };

    case ActionTypes.SET_PARUTIONS:
      return {
        ...state,
        parutions: action.payload as Print[]
      };

    case ActionTypes.SET_PANELS:
      return {
        ...state,
        panels: action.payload as Panel[]
      };

    case ActionTypes.SET_PANELSINFO:
      return {
        ...state,
        panelsInfo: action.payload as string
      };

    case ActionTypes.SET_PARUTION_LINKS:
      return {
        ...state,
        parutionFileLinks: action.payload as string[]
      };

    case ActionTypes.SET_PARUTION_COMMENT:
      return {
        ...state,
        parutionsComment: action.payload as string
      };

    case ActionTypes.SET_VISITS:
      return {
        ...state,
        visits: action.payload as Visit[]
      };

    case ActionTypes.SET_VISITCONTACT:
      return {
        ...state,
        visitContact: action.payload as VisitContact
      };

    case ActionTypes.SET_VISITADVALORIS:
      return {
        ...state,
        visitAdValoris: action.payload as VisitAdValoris
      };

    case ActionTypes.RESET_PACK:
      return {
        ...state,
        packID: '',
        immoPack: -1
      };

    case ActionTypes.SET_IMMOPACKIDS:
      return {
        ...state,
        immoPackIds: action.payload as number[]
      };

    case ActionTypes.SET_SPLIT_INVOICE:
      return {
        ...state,
        splitInvoice: action.payload as boolean
      };

    case ActionTypes.SET_ASKFOREXPERTISE:
      return {
        ...state,
        askForExpertise: action.payload as boolean
      };

    case ActionTypes.SET_ASKFOROFFER:
      return {
        ...state,
        askForOffer: action.payload as boolean
      };

    case ActionTypes.SET_AGENCY_PRODUCTS:
      return {
        ...state,
        productsForAgency: action.payload as Product[]
      };

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload as string
      };

    case ActionTypes.SET_ADDRESSKEY:
      return {
        ...state,
        addressKeyID: action.payload.id as string,
        addressKeyPrice: action.payload.price as number
      };

    default:
      return { ...state };
  }
}

/* function setServ(payload: number[]) {
  let services = [...payload];
  const indexCiterneE = services.indexOf(PRODUCTS.CITERNE_ENTERREE);
  const indexCiterneA = services.indexOf(PRODUCTS.CITERNE_AERIENNE);

  if (indexCiterneE)
} */

function checkHasImmoAccess(roles: Role[]): boolean {
  const hasImmoAccess = roles ? roles.some(r => r.RoleID === UserRole.ImmoAccess) : false;
  return hasImmoAccess;
}

function checkPriceWithoutReduction(products: Product[]) {
  let price = 0;

  products.forEach(product => {
    price += Number(product.price);
  });

  return Math.round(price * 100) / 100;
}

function checkReduction(reductions: Reduction[]) {
  let reduction = 0;
  if (reductions.length > 0) {
    reductions.forEach(reduc => {
      reduction += Number(reduc.price);
    });
  }
  return reduction;
}

function checkMaxCommission(reduc: number, comm: number) {
  let maxCom = 0;
  maxCom = comm > reduc ? reduc : comm;
  return maxCom;
}

function getEstateStyle(estateId): number {
  switch (estateId) {
    case estates.MAISON_1:
    case estates.MAISON_2:
    case estates.MAISON_3:
    case estates.MAISON_4:
    case estates.MAISON_5:
    case estates.VILLA:
      return estates.HOUSE;

    case estates.STUDIO:
    case estates.APPART_5075:
    case estates.APPART:
    case estates.DUPLEX:
    case estates.TRIPLEX:
      return estates.FLAT;

    case estates.IMMEUBLE_APPART:
      return estates.BUILDING;

    case estates.TERRAIN:
    case estates.STORE:
    case estates.INDUSTRY:
    case estates.GARAGE:
    case estates.OFFICE:
      return 4;

    default:
      return -1;
  }
}

function getEstateName(id: number): string {
  // const key = Object.keys(estates).find(k => estates[k] === id);
  if (id === -1) {
    return '';
  }

  return estates.estatesName.find(e => e.id === id).name;
}
