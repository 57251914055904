<ng-container *ngIf="step2FormGroup">
  <form [formGroup]="step2FormGroup">
    <div class="step-content">
      <section *ngIf="!($isLoggedIn | async); else loggedIn" formGroupName="user" class="section-user-disconnected">
        <div *ngIf="$newUserType | async as newUserType" class="section-user-disconnected--subsection">
          <h6>{{ 'section-user.disco.your-info' | translate }}</h6>
          <div class="section-user-disconnected--info">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'section-user.disco.title' | translate }}</mat-label>
              <mat-select formControlName="title">
                <mat-option *ngFor="let userTitle of userTitles" [value]="userTitle">
                  {{ userTitle }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="user-firstname" appearance="outline">
              <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
              <input
                matInput
                placeholder="Jane"
                name="firstname"
                autocomplete="given-name"
                formControlName="firstName"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('user.firstName')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="user-lastname" appearance="outline">
              <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
              <input
                matInput
                placeholder="Doe"
                name="lastname"
                autocomplete="family-name "
                formControlName="lastName"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('user.lastName')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="prefix">
              <mat-select formControlName="indicator" (selectionChange)="changeprefix($event)">
                <mat-option value="+32">
                  +32
                </mat-option>
                <mat-option value="+31">
                  +31
                </mat-option>
                <mat-option value="+33">
                  +33
                </mat-option>
                <mat-option value="+352">
                  +352
                </mat-option>
                <mat-option value="+49">
                  +49
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="phonegsm">
              <mat-label>{{ 'section-user.disco.gsm' | translate }}</mat-label>
              <input matInput formControlName="gsm" [mask]="mask" [placeholder]="gsmholder" required />
              <mat-error *ngIf="step2FormGroup.get('owner.gsm')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="prefix">
              <mat-select formControlName="indicatorphone">
                <mat-option value="+32">
                  +32
                </mat-option>
                <mat-option value="+31">
                  +31
                </mat-option>
                <mat-option value="+33">
                  +33
                </mat-option>
                <mat-option value="+352">
                  +352
                </mat-option>
                <mat-option value="+49">
                  +49
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="user-phone" appearance="outline" class="phonegsm">
              <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
              <input
                matInput
                placeholder="02 234 56 78"
                type="tel"
                name="phone"
                autocomplete="tel"
                formControlName="phone"
              />
              <mat-error *ngIf="step2FormGroup.get('user.phone')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="user-mail" appearance="outline">
              <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
              <input
                matInput
                placeholder="youremail@example.com"
                type="email"
                name="email"
                autocomplete="email"
                formControlName="email"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('user.email')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
              <mat-error *ngIf="step2FormGroup.get('user.email').hasError('isEmailAlreadyInUse')">
                {{ 'section-user.disco.email-error-usedemail' | translate }}
              </mat-error>
            </mat-form-field>
            <div *ngIf="newUserType === userTypes.Particulier" class="section-proprietaire--societe">
              <mat-checkbox #tvacheckbox>
                <span class="checkbox-label">{{ 'section-user.disco.societe-label' | translate }}</span>
              </mat-checkbox>
              <div *ngIf="tvacheckbox.checked" class="section-proprietaire--societe-plus">
                <mat-form-field class="user-societe-name" appearance="outline">
                  <mat-label>{{ 'section-user.disco.societe-name' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="Jane Doe corporation"
                    name="companyname"
                    autocomplete="companyname"
                    formControlName="companyname"
                  />
                  <mat-error *ngIf="step2FormGroup.get('user.companyname')?.hasError('required')">
                    {{ 'error-messages.required-blank' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="user-societe-tva" appearance="outline">
                  <mat-label>{{ 'section-user.disco.societe-tva' | translate }}</mat-label>
                  <input
                    matInput
                    placeholder="BE0831937722"
                    name="usertva"
                    autocomplete="tva"
                    formControlName="usertva"
                    (blur)="checkTva($event)"
                  />
                  <mat-error *ngIf="step2FormGroup.get('user.usertva')?.hasError('required')">
                    {{ 'error-messages.required-blank' | translate }}
                  </mat-error>
                  <mat-error *ngIf="vatMightBeInvalid">
                    {{ 'error-messages.tva-invalid' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div *ngIf="newUserType !== userTypes.Particulier" class="propriete-info">
            <mat-form-field appearance="outline" class="search-adress">
              <mat-label>{{ 'inputs.google-map-search' | translate }}</mat-label>
              <input
                matGoogleMapsAutocomplete
                [country]="['be']"
                [types]="['address']"
                (onAutocompleteSelected)="onAutocompleteSelected($event, 'user')"
                matInput
                type="text"
                placeholder="{{ 'inputs.google-map-search' | translate }}..."
              />
            </mat-form-field>
            <div class="propriete-info-form">
              <mat-form-field class="propriete-street" name="street" appearance="outline">
                <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
                <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
                <mat-error *ngIf="step2FormGroup.get('user.userStreet')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="propriete-num" appearance="outline">
                <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
                <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
                <mat-error *ngIf="step2FormGroup.get('user.userNum')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="propriete-postalcode" appearance="outline">
                <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
                <input matInput placeholder="1234" formControlName="userZip" required />
                <mat-error *ngIf="step2FormGroup.get('user.userZip')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="propriete-city" appearance="outline">
                <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
                <input matInput placeholder="Exemple ville" formControlName="userCity" required />
                <mat-error *ngIf="step2FormGroup.get('user.userCity')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="propriete-tva" appearance="outline">
                <mat-label>{{ 'section-user.disco.tva' | translate }}</mat-label>
                <input matInput placeholder="Exemple tva" formControlName="tva" (blur)="checkTva($event)" />
                <mat-error *ngIf="vatMightBeInvalid">
                  {{ 'error-messages.tva-invalid' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>
      <section
        *ngIf="
          (!($isLoggedIn | async) && ($newUserType | async) !== userTypes.Particulier) ||
          (($isLoggedIn | async) && user.userType !== userTypes.Particulier)
        "
        class="section-proprietaire"
        formGroupName="owner"
      >
        <h6>
          {{ 'section-user.disco.owner-info.title' | translate }}
          <p style="border: solid 1px red;padding:10px;font-weight: initial;">
            {{ 'section-user.disco.owner-info.extra' | translate }}
          </p>
        </h6>
        <div class="section-proprietaire--info">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'section-user.disco.title' | translate }}</mat-label>
            <mat-select formControlName="title">
              <mat-option *ngFor="let userTitle of userTitles" [value]="userTitle">
                {{ userTitle }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Jane"
              name="firstname"
              autocomplete="given-name"
              formControlName="firstName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('owner.firstName')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Doe"
              name="lastname"
              autocomplete="family-name "
              formControlName="lastName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('owner.lastName')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="prefix">
            <mat-select formControlName="indicator" (selectionChange)="changeprefix($event)">
              <mat-option value="+32">
                +32
              </mat-option>
              <mat-option value="+31">
                +31
              </mat-option>
              <mat-option value="+33">
                +33
              </mat-option>
              <mat-option value="+352">
                +352
              </mat-option>
              <mat-option value="+49">
                +49
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="phonegsm">
            <mat-label>{{ 'section-user.disco.gsm' | translate }}</mat-label>
            <input matInput formControlName="gsm" [mask]="mask" [placeholder]="gsmholder" required />
            <mat-error *ngIf="step2FormGroup.get('owner.gsm')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="prefix">
            <mat-select formControlName="indicatorphone">
              <mat-option value="+32">
                +32
              </mat-option>
              <mat-option value="+31">
                +31
              </mat-option>
              <mat-option value="+33">
                +33
              </mat-option>
              <mat-option value="+352">
                +352
              </mat-option>
              <mat-option value="+49">
                +49
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="phonegsm">
            <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
            <input matInput placeholder="02/123 45 67" formControlName="phone" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
            <input matInput placeholder="youremail@example.com" formControlName="email" />
            <div *ngIf="owner.email === null || owner.email === ''" class="email-danger">
              <fa-icon icon="exclamation-triangle"></fa-icon>
              <p class="caption">
                {{ 'section-user.disco.email-not-etude' | translate }}
              </p>
            </div>
            <div *ngIf="!(owner.email === null || owner.email === '')" class="email-danger">
              <fa-icon icon="exclamation-triangle"></fa-icon>
              <p class="caption">
                {{ 'section-user.disco.email-danger' | translate }}
              </p>
            </div>
            <mat-error *ngIf="step2FormGroup.get('owner.email')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
            <mat-error *ngIf="step2FormGroup.get('owner.email')?.hasError('isEmailAlreadyInUse')">
              {{ 'section-user.disco.email-error-usedemail' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="section-proprietaire--societe">
          <ng-container *ngIf="!($isInstallateur | async)">
            <mat-checkbox #tvacheckbox>
              <span class="checkbox-label">{{ 'section-user.disco.societe-label' | translate }}</span>
            </mat-checkbox>
            <div *ngIf="tvacheckbox.checked" class="section-proprietaire--societe-plus">
              <mat-form-field class="user-societe-name" appearance="outline">
                <mat-label>{{ 'section-user.disco.societe-name' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="Jane Doe corporation"
                  name="companyname"
                  autocomplete="companyname"
                  formControlName="companyname"
                />
                <mat-error *ngIf="step2FormGroup.get('owner.companyname')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="user-societe-tva" appearance="outline">
                <mat-label>{{ 'section-user.disco.societe-tva' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="BE 0831.937.722"
                  name="usertva"
                  autocomplete="tva"
                  formControlName="usertva"
                  mask="SS 0000.000.000"
                  (blur)="checkTva($event)"
                />
                <span class="mat-error" *ngIf="vatMightBeInvalid">
                  {{ 'error-messages.tva-invalid' | translate }}
                </span>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </section>
      <ng-template #loggedIn>
        <section class="section-user-connected">
          <h6>{{ 'section-user.disco.your-info' | translate }}</h6>
          <div>
            <p>
              <span>{{ 'section-user.disco.lastname' | translate }}:</span> {{ user.firstname }} {{ user.lastname }}
            </p>
            <p *ngIf="user.address.street">
              <span>{{ 'section-user.connected.address' | translate }}:</span> {{ user.address.street }}
              {{ user.address.number }}, {{ user.address.zip }}
              {{ user.address.city }}
            </p>
            <p>
              <span>{{ 'section-user.disco.email' | translate }}:</span> {{ user.email }}
            </p>
          </div>
        </section>
      </ng-template>
      <section class="section-propriete" formGroupName="address">
        <h6>{{ 'section-propriete.title' | translate }}</h6>
        <div class="propriete-info">
          <mat-form-field appearance="outline" class="search-adress">
            <mat-label>{{ 'inputs.google-map-search' | translate }}</mat-label>
            <input
              matGoogleMapsAutocomplete
              [country]="['be']"
              [types]="['address']"
              (onAutocompleteSelected)="onAutocompleteSelected($event, 'owner')"
              matInput
              type="text"
              placeholder="{{ 'inputs.google-map-search' | translate }}..."
            />
          </mat-form-field>
          <div class="propriete-info-form">
            <mat-form-field class="propriete-street" appearance="outline">
              <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
              <input
                matInput
                placeholder="Rue de l'exemple"
                name="address"
                autocomplete="street-address"
                formControlName="orderStreet"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('address.orderStreet')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-num" appearance="outline">
              <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
              <input
                matInput
                placeholder="1"
                name="number"
                autocomplete="street-number"
                formControlName="orderNum"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('address.orderNum')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-boxNumber" appearance="outline">
              <mat-label>{{ 'section-user.disco.boxnumber' | translate }}</mat-label>
              <input matInput placeholder="Exemple bte 1" name="boxNumber" formControlName="orderBoxNumber" />
            </mat-form-field>
            <mat-form-field class="propriete-apartmentNumber" appearance="outline">
              <mat-label>{{ 'section-user.disco.apartmentNumber' | translate }}</mat-label>
              <input matInput placeholder="Exemple 1A" name="apartmentNumber" formControlName="orderApartmentNumber" />
            </mat-form-field>
            <mat-form-field class="propriete-postalcode" appearance="outline">
              <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
              <input
                matInput
                placeholder="1234"
                name="zip"
                autocomplete="postal-code"
                formControlName="orderZip"
                required
              />
              <mat-error *ngIf="step2FormGroup.get('address.orderZip')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="propriete-city" appearance="outline">
              <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
              <input matInput placeholder="Exemple ville" name="city" formControlName="orderCity" required />
              <mat-error *ngIf="step2FormGroup.get('address.orderCity')?.hasError('required')">
                {{ 'error-messages.required-blank' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="region === 2 && !(isAvailableZipFlanders$ | async)" class="not-available">
          {{ 'section-situation.not-available' | translate }}
        </div>
      </section>
      <app-vente-type-info *ngIf="($venteType | async) > -1" [venteType$]="$venteType"></app-vente-type-info>
      <section *ngIf="!($isInstallateur | async)" class="section-accueil" formGroupName="contact">
        <h6>
          {{ 'section-accueil.title' | translate }}
          <app-tooltip [tooltipPosition]="'top'" [content]="'section-accueil.tooltip' | translate"></app-tooltip>
        </h6>
        <mat-radio-group [value]="$contactChoice | async">
          <ul>
            <li>
              <mat-radio-button value="sameAsOwner" (change)="setContactChoice('sameAsOwner')">
                {{ 'section-accueil.same-as-owner' | translate }}.
              </mat-radio-button>
            </li>
            <li
              *ngIf="
                ($newUserType | async) === userTypes.Agence ||
                ($newUserType | async) === userTypes.Notaire ||
                user.userType === userTypes.Agence ||
                user.userType === userTypes.Notaire ||
                user.userType === 1695
              "
            >
              <mat-radio-button value="keyAtAgency" (change)="setContactChoice('keyAtAgency')">
                {{ 'section-accueil.key' | translate }}
                {{
                  user.userType === userTypes.Agence || ($newUserType | async) === userTypes.Agence
                    ? ('section-accueil.at-agency.title' | translate)
                    : user.userType === userTypes.Notaire || ($newUserType | async) === userTypes.Notaire
                    ? ('section-accueil.at-notary' | translate)
                    : ('section-accueil.at-office' | translate)
                }}
              </mat-radio-button>
              <ng-container *ngIf="officeAddresses && officeAddresses.length">
                <div *ngIf="($contactChoice | async) === 'keyAtAgency'" class="consumer-address">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'section-user.connected.address' | translate }}</mat-label>
                    <mat-select
                      [value]="$addressKeyId | async"
                      (selectionChange)="setAddressKey($event.value, officeAddresses)"
                    >
                      <mat-option
                        *ngFor="let officeAddress of officeAddresses"
                        [value]="officeAddress.AddressObj.AddressID"
                      >
                        {{ officeAddress.Address }} | {{ officeAddress.DrivingMinute }} | {{ officeAddress.Price }}€
                        {{ 'section-money.tvac' | translate }} A/R
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
              <div *ngIf="($contactChoice | async) === 'keyAtAgency'" class="info-box">
                <fa-icon icon="info-circle"></fa-icon>
                <div>
                  <p>{{ 'section-accueil.at-agency.info' | translate }}</p>
                </div>
              </div>
            </li>

            <li>
              <mat-radio-button value="other" (change)="setContactChoice('other')">
                {{ 'section-accueil.other-person' | translate }}
              </mat-radio-button>
            </li>
          </ul>
        </mat-radio-group>
        <div *ngIf="($contactChoice | async) === 'other'" class="different-person">
          <mat-form-field class="accueil-firstname" appearance="outline">
            <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Jane"
              [value]="($contact | async).firstname"
              formControlName="firstName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('contact.firstName')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="accueil-lastname" appearance="outline">
            <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
            <input
              matInput
              placeholder="Doe"
              [value]="($contact | async).lastname"
              formControlName="lastName"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('contact.lastname')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="accueil-phone" appearance="outline">
            <mat-label>{{ 'section-user.disco.gsm' | translate }}</mat-label>
            <input
              matInput
              placeholder="0412/345678"
              mask="0000/000000"
              type="tel"
              [value]="($contact | async).gsm"
              formControlName="gsm"
              required
            />
            <mat-error *ngIf="step2FormGroup.get('contact.gsm')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="accueil-phone" appearance="outline">
            <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
            <input
              matInput
              placeholder="02 234 56 78"
              [value]="($contact | async).phone"
              formControlName="phone"
              mask="00 000 00 00"
              type="tel"
            />
            <!--  <mat-error *ngIf="step2FormGroup.get('contact.phone')?.hasError('required')">
              {{ 'error-messages.required-blank' | translate }}
            </mat-error>-->
          </mat-form-field>
        </div>
        <div *ngIf="($nbOfApp | async) > 1 && contactsArray" class="contacts">
          <button class="btn-primary" (click)="addContacts()">Add Contact</button>
          <div formArrayName="contacts">
            <div
              class="contacts-item"
              *ngFor="let contact of contactsArray.controls; let i = index"
              [formGroupName]="i"
            >
              <mat-form-field class="propriete-street" appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput placeholder="Description" name="description" formControlName="description" />
              </mat-form-field>
              <mat-form-field class="propriete-street" appearance="outline">
                <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
                <input matInput placeholder="FirstName" name="firstname" formControlName="firstname" />
              </mat-form-field>
              <mat-form-field class="propriete-street" appearance="outline">
                <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
                <input matInput formControlName="lastname" placeholder="Last Name" name="firstname" />
              </mat-form-field>
              <mat-form-field class="propriete-street" appearance="outline">
                <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
                <input matInput formControlName="phone" placeholder="Phone" name="phone" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>
      <section
        *ngIf="!step2HideDateButoir && !($isInstallateur | async) && !($isParticulier | async)"
        formGroupName="date"
        class="section-date"
      >
        <h6>
          {{ 'section-accueil.date-butoir.title' | translate }}
          <app-tooltip
            [tooltipPosition]="'top'"
            [content]="'section-accueil.date-butoir.tooltip' | translate"
          ></app-tooltip>
        </h6>
        <mat-form-field appearance="outline" id="date-picker">
          <input
            matInput
            [value]="dateButoire"
            [min]="minDate"
            [matDatepickerFilter]="filterWeekend"
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            formControlName="dateButoir"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </section>
      <section *ngIf="$isInstallateur | async">
        <ng-container *ngIf="$elecAgents | async as elecAgents">
          <mat-form-field appearance="outline">
            <mat-label>Liste des agents</mat-label>
            <input
              type="text"
              placeholder="Pick one"
              aria-label="Number"
              matInput
              [formControl]="elecAgentControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let elecAgent of $filteredElecAgents | async" [value]="elecAgent">
                {{ elecAgent.Name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- <mat-form-field appearance="outline">
          <mat-label>Liste des agents</mat-label>
          <mat-select formControlName="title">
            <mat-option *ngFor="let elecAgent of elecAgents" [value]="elecAgent.CompanyEmployeeId">
              {{ elecAgent.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        </ng-container>
      </section>
    </div>
    <div class="step-actions">
      <button class="btn btn-previous" matStepperPrevious>
        {{ 'navigation.previous' | translate }}
      </button>
      <button
        [disabled]="!(isAvailableZipFlanders$ | async)"
        class="btn-primary btn-next"
        (click)="toStep3()"
        matStepperNext
      >
        {{ 'navigation.next' | translate }}
      </button>
    </div>
  </form>
</ng-container>
<!-- {{ step2FormGroup.valid }} -->
